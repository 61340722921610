import { Navigate } from "react-router-dom"
import { HOME, LOGIN } from "../routes"
import storage from "../app/storage"

export default function ErrorPage() {
  const accessToken = storage.getAccessToken()

  if (accessToken) {
    return <Navigate to={HOME} replace />
  }

  return <Navigate to={LOGIN} replace />
}
