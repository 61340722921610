import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../../store"

export interface MenuState {
  collapsed: boolean
}

const initialState: MenuState = {
  collapsed: false,
}

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setCollapsedMenu: (state, action: PayloadAction<boolean>) => {
      state.collapsed = action.payload
    },
  },
})

export const { setCollapsedMenu } = menuSlice.actions

export const selectCollapsedMenu = (state: RootState) => state.menu.collapsed

export default menuSlice.reducer
