import { useState } from "react"
import Modal from "../../../app/shared/Modal"
import styles from "./index.module.scss"

const ContactModal = () => {
  const [open, setOpen] = useState(false)

  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)

  return (
    <div className={styles.contact}>
      Need help?{" "}
      <span className={styles.contactButton} onClick={openModal}>
        Contact us.
      </span>
      <Modal show={open} handleClose={closeModal} className={styles.modal}>
        <>
          <p className={styles.emailUs}>Email us </p>
          <a href="mailto:amy.gish@betterbrandanalytics.com">
            amy.gish@betterbrandanalytics.com
          </a>
        </>
      </Modal>
    </div>
  )
}

export default ContactModal
