import { FC } from "react"
import { AppImages } from "../../appImages"
import styles from "./index.module.scss"

interface Props {
  items: JSX.Element[]
}
const Breadcrumb: FC<Props> = ({ items }) => {
  return (
    <div className={styles.container}>
      {items.map((item, index) => (
        <div key={index}>
          {item}

          {index !== items.length - 1 && (
            <img
              src={AppImages.selectArrowIcon}
              alt="arrow"
              className={styles.arrow}
            />
          )}
        </div>
      ))}
    </div>
  )
}

export default Breadcrumb
