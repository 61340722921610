import { useAppSelector } from "../../../../app/hooks"
import Loading from "../../../../app/shared/Loading"
import { SliceStatus } from "../../../../app/sliceStatus"
import { getHumanNumber } from "../../utils/getHumanNumbers"
import { selectPostSummary, selectSocialStatus } from "../contentSlice"
import { SummaryCard } from "./SummaryCard"
import styles from "./index.module.scss"

export const PostSummary = () => {
  const summaryData = useAppSelector(selectPostSummary)
  const isLoading = useAppSelector(selectSocialStatus) === SliceStatus.loading

  return (
    <div className={styles.grid}>
      {isLoading ? (
        <div className={`${styles.loading} ${styles.historicalGraph}`}>
          <Loading />
          <p>Loading...</p>
        </div>
      ) : (
        <>
          {summaryData.map(({ metric, percentage, value }, index) => (
            <SummaryCard
              title={metric}
              subtitle={getHumanNumber(value)}
              percentage={percentage}
              key={metric + index}
            />
          ))}
        </>
      )}
    </div>
  )
}
