import html2canvas from "html2canvas"
import styles from "./index.module.scss"
import { AppImages } from "../../appImages"

export const FUNNEL_ID = "funnel-table"
export const HISTORICAL_ID = "historical-graph"
export const MONTHLY_KPI_ID = "monthly-kpi"
export const SEMESTER_KPI_ID = "semester-kpi"
export const QUARTERLY_KPI_ID = "quarterly-kpi"

const DownloadImageButton = ({
  id,
  fileName,
}: {
  id: string
  fileName: string
}) => {
  return (
    <div
      className={styles.downloadImageButton}
      onClick={() => downloadImageFromDiv({ id, fileName })}
    >
      <img src={AppImages.downloadIcon} alt="Download" />
    </div>
  )
}

function downloadImageFromDiv({
  id,
  fileName,
}: {
  id: string
  fileName: string
}) {
  const divToCapture = document.getElementById(id)
  if (!divToCapture) return
  html2canvas(divToCapture).then(function (canvas) {
    const a = document.createElement("a")
    a.href = canvas.toDataURL("image/png")
    a.download = `${fileName}.png`
    a.click()
  })
}

export default DownloadImageButton
