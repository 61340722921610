import { useMemo, useState } from "react"
import RadioButtons from "../../../app/shared/RadioButtons"
import styles from "./index.module.scss"
import HistoricalGraph from "./HistoricalGraph"
import DownloadImageButton, {
  HISTORICAL_ID,
} from "../../../app/shared/DownloadImageButton"
import { useAppSelector } from "../../../app/hooks"
import { selectHistoricalSteps } from "../homeSlice"

const Historical = () => {
  const [selectedOption, setSelectedOption] = useState("awareness")

  const historicalSteps = useAppSelector(selectHistoricalSteps)

  const options = useMemo(
    () =>
      historicalSteps?.map((step) => ({
        label: step
          .replace(/([a-z])([A-Z])/g, "$1 $2")
          .replace(/^./, (str) => str.toUpperCase()),
        value: step,
      })),
    [historicalSteps],
  )

  return (
    <div className={styles.funnel}>
      <div className={styles.header}>
        <p className={styles.title}>Historical</p>

        <div className={styles.buttonsContainer}>
          {options && (
            <RadioButtons
              name="historical"
              onSelectOption={setSelectedOption}
              options={options}
              selectedOption={selectedOption}
            />
          )}
          <DownloadImageButton
            fileName={`historical-${selectedOption}`}
            id={HISTORICAL_ID}
          />
        </div>
      </div>
      <HistoricalGraph selectedOption={selectedOption} />
    </div>
  )
}

export default Historical
