import { useMemo } from "react"
import styles from "./index.module.scss"

export const FunnelRow = ({
  children,
  position,
  title,
}: {
  children: JSX.Element[]
  position: number
  title: string
}) => {
  const [containerStyle, titleStyle] = useMemo(() => {
    switch (position) {
      case 0:
        return [styles.awarenessRow, styles.awareness]
      case 1:
        return [styles.considerationRow, styles.consideration]
      case 2:
        return [styles.newFollowersRow, styles.newFollowers]
      case 3:
        return [styles.newInstallsRow, styles.newInstalls]

      default:
        return [styles.awarenessRow, styles.awareness]
    }
  }, [position])

  return (
    <div className={`${styles.row} ${containerStyle}`}>
      <div className={`${styles.colorBlock} ${titleStyle}`}>{title}</div>
      {children}
    </div>
  )
}
