import { useMemo } from "react"
import {
  VerticalBarChart,
  optionsVerticalBar,
} from "../../../../../app/shared/VerticalBarChart"
import { useAppSelector } from "../../../../../app/hooks"
import { selectSixMonthChartKPIs, selectMonth } from "../../overviewSlice"
import styles from "./index.module.scss"

const SixMonthCharts = () => {
  const sixMonthCharts = useAppSelector(selectSixMonthChartKPIs)
  const selectedMonth = useAppSelector(selectMonth)

  const labels = useMemo(() => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ]

    // Encuentra el índice del mes proporcionado
    const monthIndex = months.findIndex(
      (m) =>
        m.toLowerCase() === selectedMonth.label.substring(0, 3).toLowerCase(),
    )

    if (monthIndex === -1) {
      throw new Error("Invalid month provided")
    }

    // Construye el array con los 6 meses anteriores y el mes proporcionado
    const result = []
    for (let i = 6; i >= 0; i--) {
      result.push(months[(monthIndex - i + 12) % 12])
    }

    return result
  }, [selectedMonth])

  return (
    <div className={styles.container}>
      {sixMonthCharts.map((chart) => (
        <div key={chart.metric} className={styles.socialContainer}>
          <div className={styles.title}>{chart.metric}</div>
          <div className={styles.chartContainer}>
            <VerticalBarChart
              options={optionsVerticalBar}
              data={{
                labels: labels,
                datasets: [
                  {
                    data: chart.data,
                    backgroundColor: chart.color,
                  },
                ],
              }}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

export default SixMonthCharts
