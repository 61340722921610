import { GoalScoreType } from "../OverviewScreen/overviewTypes"
import { SocialNetworks } from "../types/social-networks.enum"

export const MonthlyKPIMockedData = [
  {
    isPercentage: true,
    platform: SocialNetworks.TIK_TOK,
    kpi: "Avg. ER",
    month: 2.37,
    monthChange: 52,
    monthGoal: 3,
    goalDelta: 5,
    goalScore: GoalScoreType.ON_TRACK,
  },
  {
    isPercentage: false,
    platform: SocialNetworks.TIK_TOK,
    kpi: "Total Views",
    month: 248112,
    monthChange: -37,
    monthGoal: 100000,
    goalDelta: -4,
    goalScore: GoalScoreType.AT_RISK,
  },
  {
    isPercentage: false,
    platform: SocialNetworks.INSTAGRAM,
    kpi: "Total Views",
    month: 248112,
    monthChange: -37,
    monthGoal: 100000,
    goalDelta: -4,
    goalScore: GoalScoreType.OFF_TARGET,
  },
  {
    isPercentage: false,
    platform: SocialNetworks.APP,
    kpi: "App Page Views",
    month: 1564,
    monthChange: 116,
    monthGoal: 1500,
    goalDelta: 8,
    goalScore: GoalScoreType.ON_TRACK,
  },
  {
    isPercentage: true,
    platform: SocialNetworks.LINKED_IN,
    kpi: "Avg. ER",
    month: 22.7,
    monthChange: -1,
    monthGoal: 8.5,
    goalDelta: -1,
    goalScore: GoalScoreType.OFF_TARGET,
  },
  {
    isPercentage: false,
    platform: SocialNetworks.WEBSITE,
    kpi: "Landing Page Sessions",
    month: 112938,
    monthChange: -6,
    monthGoal: 30952,
    goalScore: GoalScoreType.NA,
  },
  {
    isPercentage: false,
    platform: SocialNetworks.APP,
    kpi: "App Installs",
    month: 357,
    monthChange: 200,
    goalScore: GoalScoreType.NA,
  },
]
