import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ChartData,
  BarElement,
  registerables,
} from "chart.js"
import { useEffect, useRef } from "react"
import { Chart } from "react-chartjs-2"
import { useAppSelector } from "../../hooks"
import { selectCollapsedMenu } from "../../layouts/Dashboard/menu/menuSlice"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { Context } from "chartjs-plugin-datalabels"
import { getHumanNumber } from "../../../features/social/utils/getHumanNumbers"

ChartJS.register(
  ...registerables,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
)

const getNumberWithK = (value: number): string => {
  if (value < 2000) return value.toString()
  const rounded = (value / 1000).toFixed(1)
  return rounded.endsWith(".0") ? Math.floor(value / 1000) + "k" : rounded + "k"
}
export const optionsStackedBar = {
  responsive: true,
  maintainAspectRatio: true,
  aspectRatio: 0.8,
  interaction: {
    mode: "index" as const,
    intersect: false,
  },
  scales: {
    x: {
      display: false,
      stacked: true,
    },
    y: {
      display: false,
      stacked: true,
      type: "linear",
      ticks: {
        stepSize: 10,
      },
    },
  },
  barThickness: 130,
  borderRadius: { topLeft: 5, topRight: 5 },
  inflateAmount: 5,
  layout: {
    autoPadding: true,
  },
  hover: {
    mode: null,
  },
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
    datalabels: {
      clamp: true,
      labels: {
        total: {
          display: "auto",
          color: "black",
          font: function (context: Context) {
            const datasetIndex = context.datasetIndex
            const datasetsLength = context.chart.data.datasets.length
            if (datasetIndex === datasetsLength - 1) {
              return { weight: "bold", size: 14 }
            } else {
              return { weight: "normal", size: 14 }
            }
          },
          formatter: function (value: number, context: Context) {
            if (context.datasetIndex === 0) return null

            const datasetIndex = context.datasetIndex
            const dataIndex = context.dataIndex
            let cumulativeSum = 0

            for (let i = 0; i <= datasetIndex; i++) {
              const dataset = context.chart.data.datasets[i]
              if (dataset && dataset.data) {
                cumulativeSum += Number(dataset.data[dataIndex]) || 0
              }
            }

            return ["\n", getHumanNumber(cumulativeSum)]
          },
          anchor: "end",
          offset: 80,
          align: "right",
        },
        month: {
          color: "black",
          formatter: function (value: number, context: Context) {
            if (context.datasetIndex === 0) return null
            return [`\n\n${context.dataset.label}`]
          },
          anchor: "end",
          offset: 80,
          align: "left",
          clip: false,
          textAlign: "end",
          font: { size: 14, weight: "bold" },
        },
        monthGoal: {
          color: "black",
          formatter: function (value: number, context: Context) {
            if (context.datasetIndex === 0) return null
            const actualValue =
              Number(context.chart.data.datasets[0].data[0]) +
              Number(context.chart.data.datasets[1].data[0])
            return [
              "\n\n\n\n",
              getHumanNumber(
                context.datasetIndex === 1
                  ? Number(context.chart.data.datasets[0].data[0])
                  : value,
              ) + " goal",
              context.datasetIndex === 1
                ? getHumanNumber(actualValue) + " actual"
                : "",
            ]
          },
          anchor: "end",
          offset: 80,
          align: "left",
          clip: false,
          textAlign: "end",
          font: { size: 14 },
        },
        value: {
          clamp: true,
          align: "top",
          anchor: "end",
          offset: -18,
          color: function (context: Context) {
            const datasetIndex = context.datasetIndex
            const datasetsLength = context.chart.data.datasets.length
            if (
              datasetIndex === datasetsLength - 1 ||
              datasetIndex === datasetsLength - 2
            )
              return "#475467"
            else return "white"
          },
          formatter: function (value: number, context: Context) {
            if (value < 100) return ""
            return value.toLocaleString()
          },
        },
      },
    },
  },
}

export const StackedBarChart = ({
  data,
  options,
}: {
  data: ChartData<"bar", (number | [number, number] | null)[], unknown>
  options: any
}) => {
  const chartRef = useRef<ChartJS>(null)
  const openMenu = useAppSelector(selectCollapsedMenu)

  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current

      const resizeChart = () => {
        if (chart.canvas && chart.canvas.parentElement) {
          const parent = chart.canvas.parentElement
          chart.canvas.width = parent?.offsetWidth ?? 1000
          chart.canvas.height = parent?.offsetHeight ?? 250
          chart.resize()
        }
      }

      window.addEventListener("resize", resizeChart)

      return () => {
        window.removeEventListener("resize", resizeChart)
        chart.destroy()
      }
    }
  }, [])

  useEffect(() => {
    //resize chart when open/close menu
    if (openMenu) {
      setTimeout(() => {
        if (chartRef.current) {
          const chart = chartRef.current

          if (chart.canvas && chart.canvas.parentElement) {
            const parent = chart.canvas.parentElement
            chart.canvas.width = parent?.offsetWidth ?? 1000
            chart.canvas.height = parent?.offsetHeight ?? 250
            chart.resize()
          }
        }
      }, 500)
    }
  }, [openMenu])

  return (
    <Chart
      type="bar"
      options={options}
      data={data}
      ref={chartRef}
      plugins={[ChartDataLabels]}
    />
  )
}
