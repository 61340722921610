import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"

import socialAPI from "../socialAPI"
import {
  SixMonthChartKPI,
  MonthlyKPI,
  QuarterlyKPI,
  SixMonthKPI,
} from "./overviewTypes"
import { SelectOption } from "../../../app/shared/Select"
import { SliceStatus } from "../../../app/sliceStatus"
import { RootState } from "../../../app/store"
import { OverviewResponseToOverviewKPIMapper } from "../mappers/api-mappers"

export interface SocialOverviewState {
  month: SelectOption
  year: SelectOption
  monthlyKPIs: MonthlyKPI[]
  quarterlyKPIs: QuarterlyKPI[]
  sixMothKPIs: SixMonthKPI[]
  sixMonthChartKPIs: SixMonthChartKPI[]
  status: SliceStatus
}

const initialState: SocialOverviewState = {
  month: { value: 1, label: "January" },
  year: { value: 2023, label: "2023" },
  monthlyKPIs: [],
  quarterlyKPIs: [],
  status: SliceStatus.idle,
  sixMothKPIs: [],
  sixMonthChartKPIs: [],
}

export const getOverviewMetrics = createAsyncThunk(
  "socialOverview/getOverviewMetrics",
  async ({ month, year }: { month: SelectOption; year: SelectOption }) => {
    const overviewMetrics = await socialAPI.getOverviewMetrics(
      Number(month.value) < 10
        ? `0${month.value.toString()}`
        : month.value.toString(),
      year.value.toString(),
    )

    return OverviewResponseToOverviewKPIMapper(overviewMetrics.data)
  },
)

export const socialOverviewSlice = createSlice({
  name: "socialHistoricalCharts",
  initialState,
  reducers: {
    setMonth: (state, action: PayloadAction<SelectOption>) => {
      state.month = action.payload
    },
    setYear: (state, action: PayloadAction<SelectOption>) => {
      state.year = action.payload
    },
    resetHistoricalCharts: (state) => {
      state.monthlyKPIs = initialState.monthlyKPIs
      state.quarterlyKPIs = initialState.quarterlyKPIs
      state.status = initialState.status
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOverviewMetrics.pending, (state) => {
        state.status = SliceStatus.loading
      })
      .addCase(
        getOverviewMetrics.fulfilled,
        (
          state,
          action: PayloadAction<{
            monthlyKPIs: MonthlyKPI[]
            quarterlyKPIs: QuarterlyKPI[]
            sixMonthKPIs: SixMonthKPI[]
            sixMonthChartKPIs: SixMonthChartKPI[]
          }>,
        ) => {
          state.status = SliceStatus.loaded
          state.monthlyKPIs = action.payload?.monthlyKPIs
          state.quarterlyKPIs = action.payload.quarterlyKPIs
          state.sixMothKPIs = action.payload.sixMonthKPIs
          state.sixMonthChartKPIs = action.payload.sixMonthChartKPIs
        },
      )
      .addCase(getOverviewMetrics.rejected, (state) => {
        state.status = SliceStatus.failed
      })
  },
})

export const { setMonth, setYear, resetHistoricalCharts } =
  socialOverviewSlice.actions

export const selectSocialStatus = (state: RootState) =>
  state.socialOverviewCharts.status
export const selectMonth = (state: RootState) =>
  state.socialOverviewCharts.month
export const selectYear = (state: RootState) => state.socialOverviewCharts.year
export const selectMonthlyKPIs = (state: RootState) =>
  state.socialOverviewCharts.monthlyKPIs
export const selectQuarterlyKPIs = (state: RootState) =>
  state.socialOverviewCharts.quarterlyKPIs
export const selectSixMonthKPIs = (state: RootState) =>
  state.socialOverviewCharts.sixMothKPIs
export const selectSixMonthChartKPIs = (state: RootState) =>
  state.socialOverviewCharts.sixMonthChartKPIs

export default socialOverviewSlice.reducer
