import { FC } from "react"
import styles from "./index.module.scss"
import MetricBadge from "../../../../../app/shared/MetricBadge"

const ArrowIcon = ({ className }: { className?: string }) => (
  <svg
    width="15"
    height="12"
    viewBox="0 0 15 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M14.1667 6H0.833374M0.833374 6L5.83337 11M0.833374 6L5.83337 1"
      stroke="currentColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

interface Props {
  title: string
  subtitle: string
  percentage: number
}

export const SummaryCard: FC<Props> = ({ title, subtitle, percentage }) => {
  return (
    <div className={styles.card}>
      <p className={styles.title}>{title}</p>
      <div className={styles.number_badge_container}>
        <p className={styles.number}>{subtitle}</p>
        <MetricBadge isPercentage numericValue={percentage} />
      </div>
    </div>
  )
}
