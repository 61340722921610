import React, { FC } from "react"
import styles from "./index.module.scss"
import SelectDate from "../../../app/shared/SelectDate/SelectDate"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { SelectOption } from "../../../app/shared/Select"

interface HeaderProps {
  selectMonth: (state: RootState) => SelectOption
  selectYear: (state: RootState) => SelectOption
  setMonth: any
  setYear: any
}

const Header: FC<HeaderProps> = ({
  selectMonth,
  selectYear,
  setMonth,
  setYear,
}) => {
  const dispatch = useAppDispatch()
  const selectedMonth = useAppSelector(selectMonth)
  const selectedYear = useAppSelector(selectYear)
  return (
    <section className={styles.header}>
      <h1 className={styles.title}>TikTok</h1>
      <SelectDate
        month={selectedMonth}
        year={selectedYear}
        onMonthChange={(month) => dispatch(setMonth(month))}
        onYearChange={(year) => dispatch(setYear(year))}
      />
    </section>
  )
}

export default Header
