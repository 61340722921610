import styles from "./index.module.scss"

interface RadioButtonType {
  label: string
  value: string
}

const RadioButtons = ({
  options,
  onSelectOption,
  selectedOption,
  name,
}: {
  options: RadioButtonType[]
  onSelectOption: (option: string) => void
  selectedOption: string
  name: string
}) => {
  return (
    <div className={styles.radioButton}>
      {options.map((option) => (
        <label
          key={option.value}
          onClick={() => onSelectOption(option.value)}
          htmlFor={option.value}
          className={selectedOption === option.value ? styles.selected : ""}
        >
          {selectedOption === option.value && (
            <div className={styles.selectedDot} />
          )}
          {option.label}
        </label>
      ))}
    </div>
  )
}

export default RadioButtons
