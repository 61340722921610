export const SixMonthKPIMockedData = [
  {
    platform: "Total Impressions",
    platformSubtitle: "(excludes TikTok, no imp data)",
    data: [3, 3, 3, 3, 3, 3, 3],
    previousMonthAverage: 3,
    isPercentage: true,
  },
  {
    platform: "Total Video Views",
    data: [100000, 100000, 100000, 100000, 100000, 100000, 100000],
    previousMonthAverage: 100000,
    isPercentage: false,
  },
  {
    platform: "Total Engagement",
    data: [1.5, 1.5, 1.5, 1.5, 1.5, 1.5, 1.5],
    previousMonthAverage: 1.5,
    isPercentage: true,
  },
  {
    platform: "Avg Engagement",
    data: [1500, 1500, 1500, 1500, 1500, 1500, 1500],
    previousMonthAverage: 1500,
    isPercentage: false,
  },
  {
    platform: "Net Audience",
    data: [8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5],
    previousMonthAverage: 8.5,
    isPercentage: true,
  },
  {
    platform: "Landing Page Sessions",
    platformSubtitle: "(from Social)",
    data: [30952, 30952, 30952, 30952, 30952, 30952, 30952],
    previousMonthAverage: 30952,
    isPercentage: false,
  },
  {
    platform: "App Installs",
    platformSubtitle: "(from Organic Social)",
    data: [
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    ],
    previousMonthAverage: undefined,
    isPercentage: false,
  },
  {
    platform: "In App Content",
    platformSubtitle: "(from Social)",
    data: [
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    ],
    previousMonthAverage: undefined,
    isPercentage: false,
  },
]

export const SixMonthChartKPIMockedData = [
  {
    metric: "View",
    data: [100000, 130000, 70000, 110000, 70000, 115000, 100000],
  },
  {
    metric: "Engagements",
    data: [100000, 130000, 70000, 110000, 70000, 115000, 100000],
  },
  {
    metric: "Followers",
    data: [100000, 130000, 70000, 110000, 70000, 115000, 100000],
  },
  {
    metric: "App Installs",
    data: [100000, 130000, 70000, 110000, 70000, 115000, 100000],
  },
]
