import {
  SixMonthChartKPI,
  MonthlyKPI,
  QuarterlyKPI,
  SixMonthKPI,
} from "../OverviewScreen/overviewTypes"
import { GetOverviewResponse } from "../types/api-response"
import { getHumanNumber } from "../utils/getHumanNumbers"
import { getSocialImage } from "../utils/getSocialImage"

const CHARTS_COLORS = [
  ["#D84A3D", "#F16C60", "#FFABA4", "#FFD1CC"],
  ["#699DBF", "#92CDF4", "#C2E3F8", "#E5EDF3"],
  ["#CC9959", "#EDBF85", "#FFDDB2", "#F9EAD7"],
]

const SIX_MONTH_CHART_COLORS = ["#EDBF85", "#F16C60", "#92CDF4", "#FFD1CC"]

export function OverviewResponseToOverviewKPIMapper(
  overviewResponse: GetOverviewResponse,
): {
  monthlyKPIs: MonthlyKPI[]
  quarterlyKPIs: QuarterlyKPI[]
  sixMonthKPIs: SixMonthKPI[]
  sixMonthChartKPIs: SixMonthChartKPI[]
} {
  const monthlyKPIs: MonthlyKPI[] = overviewResponse.monthlyKPI.map((kpi) => ({
    goalScore: kpi.goalScore,
    kpi: kpi.kpi,
    month: getHumanNumber(kpi.month),
    monthChange: kpi.monthChange,
    platform: kpi.platform,
    isPercentage: kpi.isPercentage,
    monthGoal: kpi.monthGoal ? getHumanNumber(kpi.monthGoal) : undefined,
    goalDelta: kpi.goalDelta,
  }))

  const quarterlyKPIs: QuarterlyKPI[] = overviewResponse.quarterlyKPI.map(
    (kpi, index) => {
      const [firstGoal, ...restGoals] = kpi.goals

      return {
        title: `${kpi.provider} ${kpi.metric}`,
        icon: getSocialImage(kpi.provider),
        colors:
          index > CHARTS_COLORS.length - 1
            ? CHARTS_COLORS[0]
            : CHARTS_COLORS[index],
        data: [firstGoal, kpi.currentValue, ...restGoals],
      }
    },
  )

  const sixMonthKPIs: SixMonthKPI[] = overviewResponse.sixMonthKPI.map(
    (kpi) => ({
      platform: kpi.platform,
      platformSubtitle: kpi.platformSubtitle,
      data: kpi.data.map((x) => (x ? getHumanNumber(x) : "n/a")),
      isPercentage: kpi.isPercentage,
      previousMonthAverage: kpi.previousMonthAverage
        ? getHumanNumber(kpi.previousMonthAverage)
        : "n/a",
    }),
  )

  const sixMonthChatKPIs: SixMonthChartKPI[] =
    overviewResponse.sixMonthChartKPI.map((kpi, index) => ({
      metric: kpi.metric,
      data: kpi.data,
      color: SIX_MONTH_CHART_COLORS[index],
    }))

  return {
    monthlyKPIs,
    quarterlyKPIs,
    sixMonthChartKPIs: sixMonthChatKPIs,
    sixMonthKPIs,
  }
}
