import { FC, useRef, useState } from "react"
import styles from "./index.module.scss"
import { AppImages } from "../../../../app/appImages"
import { SortDirection } from "../../types/sort-model"

const ArrowIcon = ({ className }: { className: string }) => (
  <svg
    width="15"
    height="12"
    viewBox="0 0 15 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M14.1667 6H0.833374M0.833374 6L5.83337 11M0.833374 6L5.83337 1"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

interface SelectionOption {
  label: string | JSX.Element
  value: string
}

const SORT_FIELDS: SelectionOption[] = [
  {
    label: "Date",
    value: "date",
  },
  {
    label: "Eng. Rate",
    value: "eng_rate",
  },
  {
    label: "Impressions",
    value: "impressions",
  },
]

const SORT_DIRECTIONS: SelectionOption[] = [
  {
    label: (
      <div className={styles.icon_item}>
        <ArrowIcon className={styles.down_arrow} />
        <p>High-Low</p>
      </div>
    ),
    value: SortDirection.DESC,
  },
  {
    label: (
      <div className={styles.icon_item}>
        <ArrowIcon className={styles.up_arrow} />
        <p>Low-High</p>
      </div>
    ),
    value: SortDirection.ASC,
  },
]

interface Props {
  onChange?: (field: string, direction: SortDirection) => void
}

const SortSelector: FC<Props> = ({ onChange }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [fieldSort, setFieldSort] = useState<SelectionOption>()
  const [sortDirection, setSortDirection] = useState<SelectionOption>()
  const menuRef = useRef<HTMLDivElement>(null)

  const handleSortFieldSelect = (field: SelectionOption) => {
    setFieldSort(field)

    let currentSortDirection = sortDirection
    if (!currentSortDirection) {
      setSortDirection(SORT_DIRECTIONS[0])
      currentSortDirection = SORT_DIRECTIONS[0]
    }

    if (onChange)
      onChange(field.value, currentSortDirection.value as SortDirection)
  }

  const handleSortDirectionSelect = (direction: SelectionOption) => {
    setSortDirection(direction)

    let currentFieldSort = fieldSort
    if (!currentFieldSort) {
      setFieldSort(SORT_FIELDS[0])
      currentFieldSort = SORT_FIELDS[0]
    }

    if (onChange)
      onChange(currentFieldSort.value, direction.value as SortDirection)
  }

  const toggleMenuOpen = () => {
    setIsMenuOpen((current) => !current)
  }

  return (
    <div className={styles.container}>
      <button onClick={toggleMenuOpen} className={styles.sort_button}>
        <img src={AppImages.sortIcon} alt="Sort Icon" />
        Sort
      </button>

      {isMenuOpen && (
        <div className={styles.menu} ref={menuRef}>
          <ul>
            {SORT_FIELDS.map((field) => (
              <li
                key={field.value}
                onClick={() => handleSortFieldSelect(field)}
                className={
                  field.value === fieldSort?.value ? styles.isActive : undefined
                }
              >
                {field.label}
              </li>
            ))}
          </ul>
          <hr />
          <ul>
            {SORT_DIRECTIONS.map((direction) => (
              <li
                key={direction.value}
                onClick={() => handleSortDirectionSelect(direction)}
                className={
                  direction.value === sortDirection?.value
                    ? styles.isActive
                    : undefined
                }
              >
                {direction.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default SortSelector
