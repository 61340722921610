import { SocialNetworks } from "../features/social/types/social-networks.enum"

export const HistoricalChartsMockedData = [
  {
    provider: SocialNetworks.LINKED_IN,
    data: [450, 300, 550, 600, 250, 200, 150, 500, 650, 400, 300, 100, 150],
  },
]

const getRandomSimilarValue = (value: number): number => {
  const variation = Math.random() * 0.2 // Variation of 20%
  const sign = Math.random() < 0.5 ? -1 : 1
  return value + sign * value * variation
}

export const getHistoricalMockedData = () => {
  return HistoricalChartsMockedData.map((item) => ({
    ...item,
    data: item.data.map((value) => getRandomSimilarValue(value)),
  }))
}
