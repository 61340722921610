import { useEffect, useState } from "react"
import { TablePagination } from "./Pagination"
import styles from "./index.module.scss"
import { TableItem } from "./TableItem"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import {
  getContentDetailsMetrics,
  selectPostCurrentPage,
  selectPostDetailsItems,
  selectPostDetailsTotalPages,
  selectPostSortModel,
  selectSearch,
  selectSocialDetailStatus,
  setPage,
} from "../contentSlice"
import { SliceStatus } from "../../../../app/sliceStatus"
import Loading from "../../../../app/shared/Loading"

const PostTable = () => {
  const dispatch = useAppDispatch()
  const searchValue = useAppSelector(selectSearch)
  const detailsItems = useAppSelector(selectPostDetailsItems)
  const pageCount = useAppSelector(selectPostDetailsTotalPages)
  const currentPage = useAppSelector(selectPostCurrentPage)
  const sortModel = useAppSelector(selectPostSortModel)
  const isLoading =
    useAppSelector(selectSocialDetailStatus) === SliceStatus.loading

  useEffect(() => {
    dispatch(
      getContentDetailsMetrics({
        page: currentPage,
        search: searchValue,
        sortModel,
      }),
    )
  }, [currentPage, searchValue, sortModel])

  const handlePageChange = (newPage: number) => {
    dispatch(setPage(newPage))
  }

  return (
    <div className={styles.container}>
      {isLoading ? (
        <div className={`${styles.loading} ${styles.historicalGraph}`}>
          <Loading className={styles.loading} />
          <p>Loading...</p>
        </div>
      ) : (
        <>
          <div className={styles.table}>
            {detailsItems?.map((item, index) => (
              <TableItem item={item} key={index} />
            ))}
          </div>

          <div className={styles.pagination}>
            <TablePagination
              currentPage={currentPage}
              pageCount={pageCount}
              onPageChange={handlePageChange}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default PostTable
