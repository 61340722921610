import { getHumanNumber } from "../../social/utils/getHumanNumbers"
import {
  SixMonthChartKPI,
  MonthlyKPI,
  QuarterlyKPI,
  SixMonthKPI,
} from "../OverviewScreen/overviewTypes"
import { GetOverviewResponse } from "../types/api-response"

const CHARTS_COLORS = [
  ["#D84A3D", "#F16C60", "#FFABA4", "#FFD1CC"],
  ["#699DBF", "#92CDF4", "#C2E3F8", "#E5EDF3"],
  ["#CC9959", "#EDBF85", "#FFDDB2", "#F9EAD7"],
]

const SIX_MONTH_CHART_COLORS = ["#EDBF85", "#F16C60", "#92CDF4", "#FFD1CC"]

export function OverviewResponseToOverviewKPIMapper(
  overviewResponse: GetOverviewResponse,
): {
  monthlyKPIs: MonthlyKPI[]
} {
  const monthlyKPIs: MonthlyKPI[] = overviewResponse.monthlyKPI.map((kpi) => ({
    goalScore: kpi.goalScore,
    kpi: kpi.kpi,
    month: getHumanNumber(kpi.month),
    monthChange: kpi.monthChange,
    platform: kpi.platform,
    isPercentage: kpi.isPercentage,
    monthGoal: kpi.monthGoal ? getHumanNumber(kpi.monthGoal) : undefined,
    goalDelta: kpi.goalDelta,
  }))

  return {
    monthlyKPIs,
  }
}
