import { ChangeEvent, HTMLInputTypeAttribute } from "react"
import styles from "./index.module.scss"

const Input = ({
  label,
  errorMessage,
  showError = false,
  type = "text",
  name,
  value,
  onChange,
  suffixIcon,
}: {
  label: string
  errorMessage: string
  showError?: boolean
  type?: HTMLInputTypeAttribute
  name: string
  value: string | ReadonlyArray<string> | number | undefined
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  suffixIcon?: JSX.Element
}) => {
  return (
    <label className={styles.label}>
      {label}
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        className={showError ? styles.error : ""}
      ></input>
      {suffixIcon && <div className={styles.suffixIcon}>{suffixIcon}</div>}
      <p
        className={`${styles.errorMessage} ${showError ? styles.visible : ""}`}
      >
        {errorMessage}
      </p>
    </label>
  )
}

export default Input
