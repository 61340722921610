import styles from "./index.module.scss"

const Loader = ({ className }: { className?: string }) => (
  <div className={`${styles.ldsRing} ${className ?? ""}`}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
)

export default Loader
