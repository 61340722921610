import {
  Sidebar,
  Menu,
  MenuItem,
  sidebarClasses,
  menuClasses,
  SubMenu,
} from "react-pro-sidebar"
import { Link, useLocation } from "react-router-dom"
import { useMemo } from "react"

import styles from "./index.module.scss"
import {
  CONTENT,
  FACEBOOK,
  HISTORICAL_CHARTS,
  HOME,
  INSTAGRAM,
  SOCIAL,
  TIKTOK,
} from "../../../../routes"
import { AppImages } from "../../../appImages"
import { useAppDispatch, useAppSelector } from "../../../hooks"
import { selectUser } from "../../../../features/user/userSlice"
import LogoutButton from "../../../shared/LogoutModal"
import { selectCollapsedMenu, setCollapsedMenu } from "./menuSlice"
import { UserType } from "../../../../features/user/userTypeEnum"

interface AppMapItem {
  title: string
  route?: string
  subItems?: AppMapItem[]
  icon?: string
}

function DashboardMenu() {
  const collapsed = useAppSelector(selectCollapsedMenu)
  const user = useAppSelector(selectUser)

  const dispatch = useAppDispatch()
  const toggleCollapse = () => dispatch(setCollapsedMenu(!collapsed))

  const currentPath = useLocation().pathname

  const userAppMap: AppMapItem[] = useMemo(() => {
    if (user?.type === UserType.STANDARD) {
      return [
        { title: "Home", route: HOME, icon: AppImages.homeIcon },
        {
          title: "Social",
          route: SOCIAL,
          icon: AppImages.socialIcon,
          subItems: [
            {
              title: "Overview",
              route: SOCIAL,
            },
            {
              title: "Content",
              route: `${SOCIAL}${CONTENT}`,
            },
            {
              title: "Historical Charts",
              route: `${SOCIAL}${HISTORICAL_CHARTS}`,
            },
          ],
        },
        {
          title: "Events",
          icon: AppImages.eventsIcon,
        },
      ] as AppMapItem[]
    } else {
      return [
        { title: "Home", route: HOME, icon: AppImages.homeIcon },
        {
          title: "Facebook",
          route: FACEBOOK,
          icon: AppImages.facebookIcon,
          subItems: [
            {
              title: "Overview",
              route: FACEBOOK,
            },
            {
              title: "Content",
              route: `${FACEBOOK}${CONTENT}`,
            },
            {
              title: "Historical Charts",
              route: `${FACEBOOK}${HISTORICAL_CHARTS}`,
            },
          ],
        },
        {
          title: "Instagram",
          route: INSTAGRAM,
          icon: AppImages.instagramIcon,
          subItems: [
            {
              title: "Overview",
              route: INSTAGRAM,
            },
            {
              title: "Content",
              route: `${INSTAGRAM}${CONTENT}`,
            },
            {
              title: "Historical Charts",
              route: `${INSTAGRAM}${HISTORICAL_CHARTS}`,
            },
          ],
        },
        {
          title: "TikTok",
          route: TIKTOK,
          icon: AppImages.tikTokIcon,
          subItems: [
            {
              title: "Overview",
              route: TIKTOK,
            },
            {
              title: "Content",
              route: `${TIKTOK}${CONTENT}`,
            },
            {
              title: "Historical Charts",
              route: `${TIKTOK}${HISTORICAL_CHARTS}`,
            },
          ],
        },
      ] as AppMapItem[]
    }
  }, [user])

  return (
    <Sidebar
      onBackdropClick={toggleCollapse}
      collapsed={collapsed}
      className={styles.sidebar}
      rootStyles={{
        [`.${sidebarClasses.container}`]: {
          backgroundColor: "#335AA8",
          padding: !collapsed ? "32px 16px" : "32px 12px",
        },

        [`.${menuClasses.label}`]: {
          color: "#FFF",
          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "24px",
        },
        [`.${menuClasses.button}`]: {
          borderRadius: "5px",
          paddingLeft: "12px",
          "&:hover": {
            backgroundColor: "#4A6FBA !important",
          },
        },
        [`.${menuClasses.subMenuContent}`]: {
          borderRadius: "5px",
          backgroundColor: "#335AA8",
        },
        [`.${menuClasses.subMenuContent} .${menuClasses.button}`]: {
          paddingLeft: "52px",
        },
        [`.${menuClasses.active}`]: {
          borderRadius: "5px",
          backgroundColor: "#4A6FBA !important",
        },
        [`.${menuClasses.SubMenuExpandIcon} span`]: {
          width: "8px",
          height: "8px",
        },
        [`.${menuClasses.SubMenuExpandIcon} .${menuClasses.open}`]: {
          transform: "translateY(-3px)",
        },
      }}
    >
      <img
        src={collapsed ? AppImages.smallLogo : AppImages.logoWhite}
        alt={"Better Brand"}
        className={collapsed ? styles.smallLogo : styles.logo}
      />
      <Menu
        menuItemStyles={{
          button: ({ level, active, disabled }) => {
            if (level === 0)
              return {
                color: disabled ? "#FFC" : "#FFF",
                backgroundColor: active ? "#4A6FBA" : undefined,
              }
          },
        }}
        className={styles.menu}
      >
        {userAppMap.map((userMap) => {
          if (userMap.subItems)
            return (
              <SubMenu
                key={userMap.route}
                label={userMap.title}
                component={
                  userMap.route ? <Link to={userMap.route} /> : undefined
                }
                disabled={!userMap.route}
                defaultOpen={
                  userMap.route ? currentPath.includes(userMap.route) : false
                }
                icon={
                  <img
                    src={userMap.icon}
                    alt={userMap.title}
                    className={styles.socialIcon}
                  />
                }
              >
                {userMap.subItems.map((item) => (
                  <MenuItem
                    key={item.route}
                    component={
                      item.route ? <Link to={item.route} /> : undefined
                    }
                    disabled={!item.route}
                    active={currentPath === item.route}
                  >
                    {item.title}
                  </MenuItem>
                ))}
              </SubMenu>
            )
          else
            return (
              <MenuItem
                key={userMap.route}
                component={
                  userMap.route ? <Link to={userMap.route} /> : undefined
                }
                disabled={!userMap.route}
                active={currentPath === userMap.route}
                icon={<img src={userMap.icon} alt={userMap.title} />}
              >
                {userMap.title}
              </MenuItem>
            )
        })}
      </Menu>
      <div
        className={`${styles.userItem} ${collapsed ? styles.collapsed : ""}`}
      >
        <button
          onClick={toggleCollapse}
          className={`${styles.menuArrow} ${
            collapsed ? styles.collapsedArrow : ""
          }`}
        >
          <img src={AppImages.menuArrowIcon} alt=">"></img>
        </button>
        <div className={styles.divider}></div>
        {collapsed ? (
          <div className={styles.userImage}>{user?.name.slice(0, 1)}</div>
        ) : (
          <div className={styles.row}>
            <div>
              <p>{user?.name}</p>
              <p className={styles.email}>{user?.email}</p>
            </div>
            <LogoutButton />
          </div>
        )}
      </div>
    </Sidebar>
  )
}

export default DashboardMenu
