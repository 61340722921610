import { AppImages } from "../../../app/appImages"
import { SocialNetworks } from "../types/social-networks.enum"

export const getSocialImage = (social: SocialNetworks) => {
  switch (social) {
    case SocialNetworks.INSTAGRAM:
      return AppImages.instagramIcon

    case SocialNetworks.LINKED_IN:
      return AppImages.linkedInIcon

    case SocialNetworks.TIK_TOK:
      return AppImages.tikTokIcon

    case SocialNetworks.APP:
      return AppImages.appIcon

    case SocialNetworks.WEBSITE:
      return AppImages.websiteIcon

    case SocialNetworks.FACEBOOK:
      return AppImages.facebookIcon

    default:
      return AppImages.websiteIcon
  }
}
