export const SummaryMockedData = [
  {
    metric: "Post",
    percentage: -5.15,
    value: 2500,
  },
  {
    metric: "Likes",
    percentage: 2.45,
    value: 6000,
  },
  {
    metric: "Engagement Rate",
    percentage: 2.45,
    value: 6000,
  },
  {
    metric: "Impressions",
    percentage: -5.15,
    value: 2500,
  },
  {
    metric: "Post",
    percentage: 2.45,
    value: 6000,
  },
]
