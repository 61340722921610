import { useEffect } from "react"
import { Link } from "react-router-dom"

import styles from "./index.module.scss"
import { MonthlyKPIView } from "./MonthlyKPIView"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import {
  getOverviewMetrics,
  selectAccount,
  selectHistoricalStatus,
  selectMonth,
  selectYear,
  setMonth,
  setSelectAccount,
  setYear,
} from "./overviewSlice"
import { SliceStatus } from "../../../app/sliceStatus"
import Loading from "../../../app/shared/Loading"
import Breadcrumb from "../../../app/shared/Breadcrumb"
import { TIKTOK } from "../../../routes"
import { AppImages } from "../../../app/appImages"
import Header from "../Header"
import AccountSelector from "../../../app/shared/AccountSelector"
import { selectUser } from "../../user/userSlice"

export function TiktokOverview() {
  const dispatch = useAppDispatch()
  const isLoading =
    useAppSelector(selectHistoricalStatus) === SliceStatus.loading
  const selectedMonth = useAppSelector(selectMonth)
  const selectedYear = useAppSelector(selectYear)
  const userTikTokAccounts = useAppSelector(selectUser)?.tikTokAccounts
  const selectedAccount = useAppSelector(selectAccount)

  useEffect(() => {
    dispatch(
      getOverviewMetrics({
        month: selectedMonth,
        year: selectedYear,
        account: selectedAccount,
      }),
    )
  }, [selectedYear, selectedMonth, selectedAccount])

  return (
    <div className={styles.social}>
      <Header
        selectMonth={selectMonth}
        selectYear={selectYear}
        setMonth={setMonth}
        setYear={setYear}
      />

      <section className={styles.selectorContainer}>
        <Breadcrumb
          items={[
            <Link to={TIKTOK}>
              <img src={AppImages.graySocialIcon} alt="social icon" />
            </Link>,
            <Link to={`${TIKTOK}`}>Overview</Link>,
          ]}
        />

        <div></div>

        <div className={styles.selector}>
          <AccountSelector
            options={[
              {
                label: "All",
                value: "",
              },
              ...(userTikTokAccounts?.map((account) => ({
                label: account.name,
                value: account.id,
              })) || []),
            ]}
            account={
              selectedAccount
                ? {
                    value: selectedAccount,
                    label:
                      userTikTokAccounts?.find((x) => x.id === selectedAccount)
                        ?.name || "",
                  }
                : {
                    label: "All",
                    value: "",
                  }
            }
            onAccountChange={(newSelectedAccount) =>
              dispatch(setSelectAccount(newSelectedAccount))
            }
          />
        </div>
      </section>

      {isLoading ? (
        <div className={`${styles.loading} ${styles.historicalGraph}`}>
          <Loading />
          <p>Loading...</p>
        </div>
      ) : (
        <>
          <MonthlyKPIView />
        </>
      )}
    </div>
  )
}
