import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import loginReducer from "../features/login/loginSlice"
import homeReducer from "../features/home/homeSlice"
import menuReducer from "./layouts/Dashboard/menu/menuSlice"
import userReducer from "../features/user/userSlice"

import socialOverviewReducer from "../features/social/OverviewScreen/overviewSlice"
import socialContentReducer from "../features/social/ContentScreen/contentSlice"
import socialHistoricalChartsReducer from "../features/social/HistoricalChartsScreen/historicalSlice"
import facebookContentReducer from "../features/facebook/ContentScreen/contentSlice"
import facebookOverview from "../features/facebook/OverviewScreen/overviewSlice"
import facebookHistoricalCharts from "../features/facebook/HistoricalChartsScreen/historicalSlice"
import instagramContentReducer from "../features/instagram/ContentScreen/contentSlice"
import instagramOverview from "../features/instagram/OverviewScreen/overviewSlice"
import instagramHistoricalCharts from "../features/instagram/HistoricalChartsScreen/historicalSlice"
import tiktokContentReducer from "../features/tiktok/ContentScreen/contentSlice"
import tiktokOverview from "../features/tiktok/OverviewScreen/overviewSlice"
import tiktokHistoricalCharts from "../features/tiktok/HistoricalChartsScreen/historicalSlice"

export const store = configureStore({
  reducer: {
    login: loginReducer,
    home: homeReducer,
    user: userReducer,
    menu: menuReducer,
    socialOverviewCharts: socialOverviewReducer,
    socialContent: socialContentReducer,
    socialHistoricalCharts: socialHistoricalChartsReducer,
    facebookContent: facebookContentReducer,
    facebookOverview: facebookOverview,
    facebookHistoricalCharts: facebookHistoricalCharts,
    instagramContent: instagramContentReducer,
    instagramOverview: instagramOverview,
    instagramHistoricalCharts: instagramHistoricalCharts,
    tikTokContent: tiktokContentReducer,
    tikTokOverview: tiktokOverview,
    tikTokHistoricalCharts: tiktokHistoricalCharts,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
