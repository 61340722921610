import { FC } from "react"

import styles from "./index.module.scss"
import { PostDetailItemType } from "../../contentTypes"
import { getHumanNumber } from "../../../../social/utils/getHumanNumbers"
import { getSocialImage } from "../../../../social/utils/getSocialImage"

interface Props {
  item: PostDetailItemType
}
export const TableItem: FC<Props> = ({ item }) => {
  const { date, description, image, metrics, provider } = item
  return (
    <article className={styles.container}>
      <div className={styles.image_container}>
        <img src={image} alt="post-instagram" />
      </div>
      <div className={styles.details_container}>
        <p className={styles.date}>{date}</p>

        <p className={styles.content}>{description}</p>

        <div className={styles.data_container}>
          <div className={styles.data_grid}>
            {metrics.map((data) => (
              <div key={data.metric}>
                <p className={styles.title}>{data.metric}</p>
                <p className={styles.subtitle}>
                  {getHumanNumber(data.value)} {data.isPercentage ? "%" : ""}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className={styles.icon_container}>
        <img
          src={getSocialImage(provider)}
          alt={`${provider} icon`}
          className={styles.icon}
        />
      </div>
    </article>
  )
}
