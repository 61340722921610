import { FC, useMemo } from "react"

import { AppImages } from "../../appImages"
import Select, { SelectOption } from "../Select"

import styles from "./index.module.scss"

export const monthOptions: SelectOption[] = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
]

interface SelectDateProps {
  month: SelectOption
  year: SelectOption
  onMonthChange: (newValue: SelectOption) => void
  onYearChange: (newValue: SelectOption) => void
}

const SelectDate: FC<SelectDateProps> = ({
  month,
  year,
  onMonthChange,
  onYearChange,
}) => {
  const yearOptions = useMemo(() => {
    const currentYear = new Date().getFullYear()
    const years: SelectOption[] = []

    for (let year = 2020; year <= currentYear; year++) {
      years.push({ value: year, label: year.toString() })
    }
    return years
  }, [])

  const onSelectMonth = (month: SelectOption) => onMonthChange(month)
  const onSelectYear = (year: SelectOption) => onYearChange(year)

  return (
    <div className={styles.selectDateContainer}>
      <img
        src={AppImages.calendarIcon}
        alt=""
        className={styles.calendarIcon}
      />
      <Select
        selectedOption={month}
        onClickOption={onSelectMonth}
        options={monthOptions}
        className={styles.selectMonthContainer}
      />
      <Select
        selectedOption={year}
        onClickOption={onSelectYear}
        options={yearOptions}
        className={styles.selectYearContainer}
      />
    </div>
  )
}

export default SelectDate
