import { Link } from "react-router-dom"
import { useEffect } from "react"

import Breadcrumb from "../../../app/shared/Breadcrumb"
import { FACEBOOK, HISTORICAL_CHARTS } from "../../../routes"
import { AppImages } from "../../../app/appImages"
import HistoricalGraph from "./HistoricalGraph"
import DownloadImageButton from "../../../app/shared/DownloadImageButton"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import {
  getHistoricalCharts,
  selectAccount,
  selectHistoricalCharts,
  selectHistoricalStatus,
  selectMonth,
  selectYear,
  setMonth,
  setSelectedAccount,
  setYear,
} from "./historicalSlice"
import { SliceStatus } from "../../../app/sliceStatus"
import Loading from "../../../app/shared/Loading"

import styles from "./index.module.scss"
import Header from "../Header"
import AccountSelector from "../../../app/shared/AccountSelector"
import { selectUser } from "../../user/userSlice"

const FacebookHistoricalChartsScreen = () => {
  const historicalCharts = useAppSelector(selectHistoricalCharts)
  const dispatch = useAppDispatch()
  const isLoading =
    useAppSelector(selectHistoricalStatus) === SliceStatus.loading
  const selectedMonth = useAppSelector(selectMonth)
  const selectedYear = useAppSelector(selectYear)
  const userFacebookAccounts = useAppSelector(selectUser)?.facebookPages
  const selectedAccount = useAppSelector(selectAccount)

  useEffect(() => {
    dispatch(
      getHistoricalCharts({
        month: selectedMonth,
        year: selectedYear,
        account: selectedAccount,
      }),
    )
  }, [selectedYear, selectedMonth, selectedAccount])

  return (
    <div className={styles.social}>
      <Header
        selectMonth={selectMonth}
        selectYear={selectYear}
        setMonth={setMonth}
        setYear={setYear}
      />

      <section className={styles.selectorContainer}>
        <Breadcrumb
          items={[
            <Link to={FACEBOOK}>
              <img src={AppImages.graySocialIcon} alt="social icon" />
            </Link>,
            <Link to={`${FACEBOOK}${HISTORICAL_CHARTS}`}>
              Historical Charts
            </Link>,
          ]}
        />

        <div />

        <div className={styles.selector}>
          <AccountSelector
            options={[
              {
                label: "All",
                value: "",
              },
              ...(userFacebookAccounts?.map((account) => ({
                label: account.name,
                value: account.id,
              })) || []),
            ]}
            account={
              selectedAccount
                ? {
                    value: selectedAccount,
                    label:
                      userFacebookAccounts?.find(
                        (x) => x.id === selectedAccount,
                      )?.name || "",
                  }
                : {
                    label: "All",
                    value: "",
                  }
            }
            onAccountChange={(newSelectedAccount) =>
              dispatch(setSelectedAccount(newSelectedAccount))
            }
          />
        </div>
      </section>

      {isLoading ? (
        <div className={`${styles.loading} ${styles.historicalGraph}`}>
          <Loading />
          <p>Loading...</p>
        </div>
      ) : (
        <>
          {Object.entries(historicalCharts).map(
            ([metricName, chartData], index) => (
              <section className={styles.summary} key={metricName}>
                <div className={styles.header}>
                  <div className={styles.titleContainer}>
                    <h2 className={styles.title}>{metricName}</h2>
                    <DownloadImageButton
                      id={metricName}
                      fileName={`social-historical-${metricName}`}
                    />
                  </div>
                </div>
                <HistoricalGraph
                  graphIndex={index}
                  chartDatasets={chartData}
                  metricName={metricName}
                />
              </section>
            ),
          )}
        </>
      )}
    </div>
  )
}

export default FacebookHistoricalChartsScreen
