import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"

import facebookAPI from "../facebookAPI"
import {
  SixMonthChartKPI,
  MonthlyKPI,
  QuarterlyKPI,
  SixMonthKPI,
} from "./overviewTypes"
import { SelectOption } from "../../../app/shared/Select"
import { SliceStatus } from "../../../app/sliceStatus"
import { RootState } from "../../../app/store"
import { OverviewResponseToOverviewKPIMapper } from "../mappers/api-mappers"

export interface FacebookOverviewState {
  month: SelectOption
  year: SelectOption
  monthlyKPIs: MonthlyKPI[]
  quarterlyKPIs: QuarterlyKPI[]
  sixMothKPIs: SixMonthKPI[]
  sixMonthChartKPIs: SixMonthChartKPI[]
  status: SliceStatus
  account?: string
}

const initialState: FacebookOverviewState = {
  month: { value: 1, label: "January" },
  year: { value: 2023, label: "2023" },
  monthlyKPIs: [],
  quarterlyKPIs: [],
  status: SliceStatus.idle,
  sixMothKPIs: [],
  sixMonthChartKPIs: [],
  account: undefined,
}

export const getOverviewMetrics = createAsyncThunk(
  "facebookOverview/getOverviewMetrics",
  async ({
    month,
    year,
    account,
  }: {
    month: SelectOption
    year: SelectOption
    account?: string
  }) => {
    const overviewMetrics = await facebookAPI.getOverviewMetrics({
      month:
        Number(month.value) < 10
          ? `0${month.value.toString()}`
          : month.value.toString(),
      year: year.value.toString(),
      account,
    })

    return OverviewResponseToOverviewKPIMapper(overviewMetrics.data)
  },
)

export const facebookOverviewSlice = createSlice({
  name: "facebookHistoricalCharts",
  initialState,
  reducers: {
    setMonth: (state, action: PayloadAction<SelectOption>) => {
      state.month = action.payload
    },
    setYear: (state, action: PayloadAction<SelectOption>) => {
      state.year = action.payload
    },
    setSelectAccount: (state, action: PayloadAction<string | undefined>) => {
      state.account = action.payload
    },
    resetHistoricalCharts: (state) => {
      state.monthlyKPIs = initialState.monthlyKPIs
      state.quarterlyKPIs = initialState.quarterlyKPIs
      state.status = initialState.status
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOverviewMetrics.pending, (state) => {
        state.status = SliceStatus.loading
      })
      .addCase(
        getOverviewMetrics.fulfilled,
        (
          state,
          action: PayloadAction<{
            monthlyKPIs: MonthlyKPI[]
          }>,
        ) => {
          state.status = SliceStatus.loaded
          state.monthlyKPIs = action.payload?.monthlyKPIs
        },
      )
      .addCase(getOverviewMetrics.rejected, (state) => {
        state.status = SliceStatus.failed
      })
  },
})

export const { setMonth, setYear, resetHistoricalCharts, setSelectAccount } =
  facebookOverviewSlice.actions

export const selectHistoricalStatus = (state: RootState) =>
  state.facebookOverview.status
export const selectAccount = (state: RootState) =>
  state.facebookOverview.account
export const selectMonth = (state: RootState) => state.facebookOverview.month
export const selectYear = (state: RootState) => state.facebookOverview.year
export const selectMonthlyKPIs = (state: RootState) =>
  state.facebookOverview.monthlyKPIs
export const selectQuarterlyKPIs = (state: RootState) =>
  state.facebookOverview.quarterlyKPIs
export const selectSixMonthKPIs = (state: RootState) =>
  state.facebookOverview.sixMothKPIs
export const selectSixMonthChartKPIs = (state: RootState) =>
  state.facebookOverview.sixMonthChartKPIs

export default facebookOverviewSlice.reducer
