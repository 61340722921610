import { SocialNetworks } from "../types/social-networks.enum"

export interface MonthlyKPI {
  platform: SocialNetworks
  kpi: string
  month: string
  monthChange: number
  monthGoal?: string
  goalDelta?: number
  goalScore: GoalScoreType
  isPercentage: boolean
}

export enum GoalScoreType {
  ON_TRACK = "On Track",
  AT_RISK = "At risk",
  OFF_TARGET = "Off target",
  NA = "n/a",
}

export interface QuarterlyKPI {
  title: string
  icon: string
  data: number[]
  colors: string[]
}

export interface SixMonthKPI {
  platform: string
  platformSubtitle?: string
  data: string[]
  isPercentage: boolean
  previousMonthAverage: string
}

export interface SixMonthChartKPI {
  metric: string
  data: number[]
  color: string
}
