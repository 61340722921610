import axios from "axios"
import { User } from "./userSlice"
import { SocialNetworks } from "../social/types/social-networks.enum"

async function getUserData(): Promise<User> {
  const user = await axios.get<User>("/user")

  const userAccounts = [
    { id: "1", name: "YellowSquare Rome", type: SocialNetworks.FACEBOOK },
    { id: "2", name: "YellowSquare Florence", type: SocialNetworks.FACEBOOK },
    {
      id: "3",
      name: "Bargiù at YellowSquare Florence",
      type: SocialNetworks.FACEBOOK,
    },
    { id: "4", name: "The Yellow Bar", type: SocialNetworks.FACEBOOK },
    { id: "5", name: "YellowSquare Milan", type: SocialNetworks.FACEBOOK },
    {
      id: "6",
      name: "YellowSquare Salone14 at YellowSquare Milan",
      type: SocialNetworks.FACEBOOK,
    },
    { id: "7", name: "YellowSquare Rome", type: SocialNetworks.INSTAGRAM },
    {
      id: "8",
      name: "Bargiù at YellowSquare Florence",
      type: SocialNetworks.INSTAGRAM,
    },
    { id: "9", name: "The Yellow Bar", type: SocialNetworks.INSTAGRAM },
    {
      id: "10",
      name: "YellowSquare Salone14 at YellowSquare Milan",
      type: SocialNetworks.INSTAGRAM,
    },
    {
      id: "11",
      name: "YellowSquare",
      type: SocialNetworks.TIK_TOK,
    },
  ]

  return {
    ...user.data,
    facebookPages: userAccounts.filter(
      (x) => x.type === SocialNetworks.FACEBOOK,
    ),
    instagramAccount: userAccounts.filter(
      (x) => x.type === SocialNetworks.INSTAGRAM,
    ),
    tikTokAccounts: userAccounts.filter(
      (x) => x.type === SocialNetworks.TIK_TOK,
    ),
  }
}

const userAPI = { getUserData }
export default userAPI
