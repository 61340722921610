import TopMetrics from "./TopMetrics"
import styles from "./index.module.scss"
import Funnel from "./Funnel"
import Historical from "./Historical"
import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  getMetrics,
  selectHomeStatus,
  selectMonth,
  selectYear,
  setMonth,
  setYear,
} from "./homeSlice"
import { SliceStatus } from "../../app/sliceStatus"
import { monthOptions } from "../../app/shared/SelectDate/SelectDate"
import { toast } from "react-toastify"

export default function Home() {
  const dispatch = useAppDispatch()
  const dataStatus = useAppSelector(selectHomeStatus)
  const selectedMonth = useAppSelector(selectMonth)
  const selectedYear = useAppSelector(selectYear)

  useEffect(() => {
    if (dataStatus === SliceStatus.idle) {
      const today = new Date()
      const currentYear = today.getFullYear()
      today.setMonth(today.getMonth() - 1)

      const currentMonthOption = monthOptions.find(
        (option) => option.value === today.getMonth() + 1,
      )

      dispatch(setMonth(currentMonthOption!))
      dispatch(setYear({ value: currentYear, label: currentYear.toString() }))
    }
  }, [dataStatus, dispatch])

  useEffect(() => {
    const controller = new AbortController()

    const selectedDate = new Date(
      `${selectedMonth.value}/31/${selectedYear.label}`,
    )
    const today = new Date()
    if (
      (selectedMonth.value as number) - 1 === today.getMonth() &&
      selectedYear.value === today.getFullYear()
    ) {
      toast.error(
        "Current month does not have full month data, please select prior month",
      )
    } else if (selectedDate > new Date()) {
      toast.error(
        "You have a selected a future month, data is not available yet",
      )
      return
    }

    dispatch(
      getMetrics({
        month: selectedMonth,
        year: selectedYear,
        controller,
      }),
    )

    return () => {
      controller.abort()
    }
  }, [selectedYear, selectedMonth, dispatch])

  return (
    <div className={styles.home}>
      <TopMetrics />
      <Funnel />
      <Historical />
    </div>
  )
}
