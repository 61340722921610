import { useEffect } from "react"
import SelectDate from "../../../app/shared/SelectDate/SelectDate"
import styles from "./index.module.scss"
import { MonthlyKPIView } from "./MonthlyKPIView"
import { QuarterlyKPI } from "./QuarterlyKPI"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import {
  getOverviewMetrics,
  selectMonth,
  selectSocialStatus,
  selectYear,
  setMonth,
  setYear,
} from "./overviewSlice"
import { SliceStatus } from "../../../app/sliceStatus"
import Loading from "../../../app/shared/Loading"
import Breadcrumb from "../../../app/shared/Breadcrumb"
import { Link } from "react-router-dom"
import { SOCIAL } from "../../../routes"
import { AppImages } from "../../../app/appImages"
import { SixMonthKPIView } from "./SixMonthKPIView"

export function Social() {
  const dispatch = useAppDispatch()
  const isLoading = useAppSelector(selectSocialStatus) === SliceStatus.loading
  const selectedMonth = useAppSelector(selectMonth)
  const selectedYear = useAppSelector(selectYear)

  useEffect(() => {
    dispatch(getOverviewMetrics({ month: selectedMonth, year: selectedYear }))
  }, [selectedYear, selectedMonth])

  return (
    <div className={styles.social}>
      <div className={styles.header}>
        <p className={styles.title}>Social</p>
        <SelectDate
          month={selectedMonth}
          year={selectedYear}
          onMonthChange={(month) => dispatch(setMonth(month))}
          onYearChange={(year) => dispatch(setYear(year))}
        />{" "}
      </div>

      <section>
        <Breadcrumb
          items={[
            <Link to={SOCIAL}>
              <img src={AppImages.graySocialIcon} alt="social icon" />
            </Link>,
            <Link to={`${SOCIAL}`}>Overview</Link>,
          ]}
        />
      </section>

      {isLoading ? (
        <div className={`${styles.loading} ${styles.historicalGraph}`}>
          <Loading />
          <p>Loading...</p>
        </div>
      ) : (
        <>
          <MonthlyKPIView />
          <QuarterlyKPI />
          <SixMonthKPIView />
        </>
      )}
    </div>
  )
}
