import { SocialNetworks } from "../features/social/types/social-networks.enum"

export const QuarterlyKPIMockedData = [
  {
    provider: SocialNetworks.TIK_TOK,
    metric: "Views",
    currentValue: 12000,
    goals: [10000, 14000, 14000],
  },
  {
    provider: SocialNetworks.LINKED_IN,
    metric: "Engagements",
    currentValue: 2500,
    goals: [10000, 14000, 14000],
  },
  {
    provider: SocialNetworks.INSTAGRAM,
    metric: "Engagements",
    currentValue: 64,
    goals: [1500, 1500, 1500],
  },
]
