import { Link } from "react-router-dom"
import { useEffect, useState } from "react"

import { AppImages } from "../../../app/appImages"
import Breadcrumb from "../../../app/shared/Breadcrumb"
import SelectDate from "../../../app/shared/SelectDate/SelectDate"
import { PostSummary } from "./PostSummary"
import PostTable from "./PostTable"
import { SearchBar } from "./SearchBar"
import SocialSelector from "./SocialSelector"
import SortSelector from "./SortSelector"
import styles from "./index.module.scss"
import { CONTENT, SOCIAL } from "../../../routes"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import {
  getContentMetrics,
  selectMonth,
  selectSocial,
  selectYear,
  setMonth,
  setYear,
  setProvider,
  selectSearch,
  setSearch,
  setPage,
  setSortModel,
} from "./contentSlice"

const ContentScreen = () => {
  const dispatch = useAppDispatch()
  const selectedMonth = useAppSelector(selectMonth)
  const selectedYear = useAppSelector(selectYear)
  const selectedSocial = useAppSelector(selectSocial)
  const searchValue = useAppSelector(selectSearch)

  useEffect(() => {
    dispatch(
      getContentMetrics({
        month: selectedMonth,
        year: selectedYear,
        provider: selectedSocial,
      }),
    )
  }, [selectedYear, selectedMonth, selectedSocial])

  return (
    <div className={styles.social}>
      <section className={styles.header}>
        <h1 className={styles.title}>Social</h1>
        <SelectDate
          month={selectedMonth}
          year={selectedYear}
          onMonthChange={(month) => dispatch(setMonth(month))}
          onYearChange={(year) => dispatch(setYear(year))}
        />
      </section>

      <section>
        <Breadcrumb
          items={[
            <Link to={SOCIAL}>
              <img src={AppImages.graySocialIcon} alt="social icon" />
            </Link>,
            <Link to={`${SOCIAL}${CONTENT}`}>Content</Link>,
          ]}
        />
      </section>

      <section className={styles.channel}>
        <div className={styles.header}>
          <div className={styles.titleContainer}>
            <p className={styles.title}>Channel</p>
            <p className={styles.subtitle}>Select social media channel</p>
          </div>
          <div className={styles.buttonsContainer}>
            <SocialSelector
              social={selectedSocial}
              onSocialChange={(social) => dispatch(setProvider(social))}
            />
          </div>
          <div></div>
        </div>
      </section>

      <>
        <section className={styles.summary}>
          <div className={styles.header}>
            <div className={styles.titleContainer}>
              <h2 className={styles.title}>Post Summary</h2>
            </div>
          </div>

          <PostSummary />
        </section>

        <section className={styles.details}>
          <div className={styles.header}>
            <h2 className={styles.title}>Post Details</h2>
            <div>
              <SearchBar
                searchValue={searchValue}
                onChange={(value) => {
                  dispatch(setSearch(value))
                  dispatch(setPage(1))
                }}
              />
              <SortSelector
                onChange={(field, direction) => {
                  dispatch(setSortModel({ field, direction }))
                  dispatch(setPage(1))
                }}
              />
            </div>
          </div>

          <PostTable />
        </section>
      </>
    </div>
  )
}

export default ContentScreen
