import DownloadImageButton, {
  MONTHLY_KPI_ID,
} from "../../../../app/shared/DownloadImageButton"
import { MonthlyKPITable } from "./Table"
import styles from "./index.module.scss"

export const MonthlyKPIView = () => {
  return (
    <div className={styles.monthlyKPIView} id={MONTHLY_KPI_ID}>
      <div className={styles.header}>
        <div>
          <h2 className={styles.title}>Monthly KPI View</h2>
          <p className={styles.subtitle}>
            Subtitle here in case more context is needed
          </p>
        </div>
        <DownloadImageButton id={MONTHLY_KPI_ID} fileName={"monthly-kpi"} />
      </div>
      <MonthlyKPITable />
    </div>
  )
}
