import styles from "./index.module.scss"
import Loader from "../Loader"

const Button = ({
  text,
  onClick,
  className,
  submit,
  disabled,
  loading,
}: {
  text: string
  disabled?: boolean
  submit?: boolean
  className?: string
  loading?: boolean
  onClick?: (event: React.MouseEvent) => void
}) => {
  return (
    <button
      className={`${styles.button} ${className ?? ""}`}
      onClick={onClick}
      type={submit ? "submit" : "button"}
      disabled={disabled}
    >
      {loading ? <Loader /> : <>{text}</>}
    </button>
  )
}

export default Button
