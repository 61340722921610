import Metrics from "./Metrics"
import SelectDate from "../../../app/shared/SelectDate/SelectDate"
import WelcomeMessage from "./WelcomeMessage"
import styles from "./index.module.scss"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import {
  selectHomeStatus,
  selectMonth,
  selectYear,
  setMonth,
  setYear,
} from "../homeSlice"
import { SliceStatus } from "../../../app/sliceStatus"

const TopMetrics = () => {
  const isLoading = useAppSelector(selectHomeStatus) === SliceStatus.loading
  const dispatch = useAppDispatch()

  const selectedMonth = useAppSelector(selectMonth)
  const selectedYear = useAppSelector(selectYear)

  return (
    <div className={styles.topMetrics}>
      <div className={styles.header}>
        <WelcomeMessage />
        <SelectDate
          month={selectedMonth}
          year={selectedYear}
          onMonthChange={(month) => {
            dispatch(setMonth(month))
          }}
          onYearChange={(year) => {
            dispatch(setYear(year))
          }}
        />
      </div>
      <Metrics isLoading={isLoading} />
    </div>
  )
}

export default TopMetrics
