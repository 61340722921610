import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { SliceStatus } from "../../app/sliceStatus"
import { RootState, AppThunk } from "../../app/store"
import loginApi from "./loginAPI"
import formValidations from "../../app/formValidations"
import storage from "../../app/storage"
import axios from "axios"

export interface LoginState {
  email: string
  password: string
  showValidationErrors: boolean
  status: SliceStatus
}

const initialState: LoginState = {
  email: "",
  password: "",
  showValidationErrors: false,
  status: SliceStatus.idle,
}

export const tryLogin = (): AppThunk => async (dispatch, getState) => {
  const state = getState().login
  if (formValidations.validateEmail(state.email) && state.password.length > 0) {
    dispatch(login({ email: state.email, password: state.password }))
  } else {
    dispatch(showValidationErrors(true))
  }
}

export const login = createAsyncThunk(
  "login/login",
  async ({ email, password }: { email: string; password: string }) => {
    const response = await loginApi.login(email, password)

    return response.data.token
  },
)

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    onChangeEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload
      state.showValidationErrors = false
    },
    onChangePassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload
      state.showValidationErrors = false
    },
    showValidationErrors: (state, action: PayloadAction<boolean>) => {
      state.showValidationErrors = action.payload
    },
    resetLogin: (state) => {
      state.email = initialState.email
      state.password = initialState.password
      state.showValidationErrors = initialState.showValidationErrors
      state.status = initialState.status
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = SliceStatus.loading
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = SliceStatus.loaded
        storage.setAccessToken(action.payload)
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${action.payload}`
      })
      .addCase(login.rejected, (state) => {
        state.status = SliceStatus.failed
      })
  },
})

export const {
  onChangeEmail,
  onChangePassword,
  showValidationErrors,
  resetLogin,
} = loginSlice.actions

export const selectEmail = (state: RootState) => state.login.email
export const selectPassword = (state: RootState) => state.login.password
export const selectLoginStatus = (state: RootState) => state.login.status
export const selectShowError = (state: RootState) =>
  state.login.showValidationErrors

export default loginSlice.reducer
