export const SummaryMockedData = [
  {
    metric: "Post",
    percentage: -5.15,
    value: 2500,
  },
  {
    metric: "Likes",
    percentage: 2.45,
    value: 6000,
  },
  {
    metric: "Engagement Rate",
    percentage: 2.45,
    value: 6000,
  },
  {
    metric: "Impressions",
    percentage: -5.15,
    value: 2500,
  },
  {
    metric: "Post",
    percentage: 2.45,
    value: 6000,
  },
]

const getRandomSimilarValue = (value: number): number => {
  const variation = Math.random() * 0.2 // Variation of 20%
  const sign = Math.random() < 0.5 ? -1 : 1
  return value + sign * value * variation
}

export const getSummaryMockedData = () => {
  return SummaryMockedData.map((item) => ({
    ...item,
    percentage: getRandomSimilarValue(item.percentage),
    value: getRandomSimilarValue(item.value),
  }))
}
