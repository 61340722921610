import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ChartData,
  BarElement,
  registerables,
} from "chart.js"
import { useEffect, useRef } from "react"
import { Chart, ChartProps } from "react-chartjs-2"
import { useAppSelector } from "../../hooks"
import { selectCollapsedMenu } from "../../layouts/Dashboard/menu/menuSlice"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { getHumanNumber } from "../../../features/social/utils/getHumanNumbers"

ChartJS.register(
  ...registerables,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
)

export const optionsVerticalBar = {
  responsive: true,
  maintainAspectRatio: true,
  interaction: {
    mode: "index" as const,
    intersect: false,
  },

  scales: {
    x: {
      display: true,
      stacked: true,
      ticks: {
        display: true,
      },
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,

      display: true,
      stacked: true,
      type: "linear",
      ticks: {
        display: false,
      },
      grid: {
        color: "#F2F4F7",
      },
      title: {
        display: true,
        text: "Active users",
        color: "#667085",
        font: {
          size: 12,
          weight: "bold",
          style: "Inter",
        },

        rotation: 270,
      },
    },
  },
  borderRadius: { topLeft: 5, topRight: 5 },
  layout: {
    padding: {
      top: 10,
    },
  },
  hover: {
    mode: null,
  },
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
    datalabels: {
      anchor: "end",
      align: "end",
      formatter: (value: number) => getHumanNumber(value),
      color: "black",
    },
  },
}

export const VerticalBarChart = ({
  data,
  options,
}: {
  data: ChartData<"bar", (number | [number, number] | null)[], unknown>
  options: any
}) => {
  const chartRef = useRef<ChartJS>(null)
  const openMenu = useAppSelector(selectCollapsedMenu)

  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current

      const resizeChart = () => {
        if (chart.canvas && chart.canvas.parentElement) {
          const parent = chart.canvas.parentElement
          chart.canvas.width = parent?.offsetWidth ?? 1000
          chart.canvas.height = parent?.offsetHeight ?? 250
          chart.resize()
        }
      }

      window.addEventListener("resize", resizeChart)

      return () => {
        window.removeEventListener("resize", resizeChart)
        chart.destroy()
      }
    }
  }, [])

  useEffect(() => {
    //resize chart when open/close menu
    if (openMenu) {
      setTimeout(() => {
        if (chartRef.current) {
          const chart = chartRef.current

          if (chart.canvas && chart.canvas.parentElement) {
            const parent = chart.canvas.parentElement
            chart.canvas.width = parent?.offsetWidth ?? 1000
            chart.canvas.height = parent?.offsetHeight ?? 250
            chart.resize()
          }
        }
      }, 500)
    }
  }, [openMenu])

  return (
    <Chart
      type="bar"
      options={options}
      data={data}
      ref={chartRef}
      plugins={[ChartDataLabels]}
    />
  )
}
