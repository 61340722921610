import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { SliceStatus } from "../../app/sliceStatus"
import { RootState } from "../../app/store"
import homeApi from "./homeAPI"
import { SelectOption } from "../../app/shared/Select"
import { HomeMetrics } from "./homeTypes"
import axios from "axios"

export interface HomeState {
  month: SelectOption
  year: SelectOption
  homeMetrics?: HomeMetrics
  funnelSteps?: string[]
  historicalSteps?: string[]
  status: SliceStatus
}

const initialState: HomeState = {
  month: { value: 1, label: "January" },
  year: { value: 2023, label: "2023" },
  homeMetrics: undefined,
  status: SliceStatus.idle,
  funnelSteps: undefined,
  historicalSteps: undefined,
}

export const getMetrics = createAsyncThunk(
  "home/getMetrics",
  async (
    {
      month,
      year,
      controller,
    }: {
      month: SelectOption
      year: SelectOption
      controller?: AbortController
    },
    { rejectWithValue },
  ) => {
    try {
      const [homeMetrics] = await Promise.all([
        homeApi.getMetrics(
          Number(month.value) < 10
            ? `0${month.value.toString()}`
            : month.value.toString(),
          year.value.toString(),
          controller,
        ),
      ])

      return {
        homeMetrics: homeMetrics.data,
      }
    } catch (error: any) {
      if (axios.isCancel(error)) {
        return rejectWithValue("Request was canceled")
      }
      return rejectWithValue(error.message)
    }
  },
)

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setMonth: (state, action: PayloadAction<SelectOption>) => {
      state.month = action.payload
    },
    setYear: (state, action: PayloadAction<SelectOption>) => {
      state.year = action.payload
    },
    resetHome: (state) => {
      state.status = initialState.status
      state.homeMetrics = initialState.homeMetrics
      state.funnelSteps = initialState.funnelSteps
      state.historicalSteps = initialState.historicalSteps
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMetrics.pending, (state) => {
        state.status = SliceStatus.loading
      })
      .addCase(
        getMetrics.fulfilled,
        (
          state,
          action: PayloadAction<{
            homeMetrics: HomeMetrics
          }>,
        ) => {
          state.status = SliceStatus.loaded
          state.homeMetrics = action.payload.homeMetrics

          const funnelKeys = Object.keys(
            action.payload.homeMetrics.funnelMetrics,
          )
          state.funnelSteps = funnelKeys.length > 1 ? funnelKeys : undefined

          const historicalKeys = Object.keys(
            action.payload.homeMetrics.historicalMetrics,
          )
          state.historicalSteps =
            historicalKeys.length > 1 ? historicalKeys : undefined
        },
      )
      .addCase(getMetrics.rejected, (state, action) => {
        if (action.payload !== "Request was canceled")
          state.status = SliceStatus.failed
      })
  },
})

export const { setMonth, setYear, resetHome } = homeSlice.actions

export const selectHomeStatus = (state: RootState) => state.home.status
export const selectMonth = (state: RootState) => state.home.month
export const selectYear = (state: RootState) => state.home.year
export const selectHomeMetrics = (state: RootState) => state.home.homeMetrics
export const selectFunnelSteps = (state: RootState) => state.home.funnelSteps
export const selectHistoricalSteps = (state: RootState) =>
  state.home.historicalSteps

export default homeSlice.reducer
