import { GoalScoreType } from "../features/social/OverviewScreen/overviewTypes"
import { SocialNetworks } from "../features/social/types/social-networks.enum"

export const MonthlyKPIMockedData = [
  {
    isPercentage: true,
    platform: SocialNetworks.TIK_TOK,
    kpi: "Avg. ER",
    month: 2.37,
    monthChange: 52,
    monthGoal: 3,
    goalDelta: 5,
    goalScore: GoalScoreType.ON_TRACK,
  },
  {
    isPercentage: false,
    platform: SocialNetworks.TIK_TOK,
    kpi: "Total Views",
    month: 248112,
    monthChange: -37,
    monthGoal: 100000,
    goalDelta: -4,
    goalScore: GoalScoreType.AT_RISK,
  },
  {
    isPercentage: false,
    platform: SocialNetworks.INSTAGRAM,
    kpi: "Total Views",
    month: 248112,
    monthChange: -37,
    monthGoal: 100000,
    goalDelta: -4,
    goalScore: GoalScoreType.OFF_TARGET,
  },
  {
    isPercentage: false,
    platform: SocialNetworks.APP,
    kpi: "App Page Views",
    month: 1564,
    monthChange: 116,
    monthGoal: 1500,
    goalDelta: 8,
    goalScore: GoalScoreType.ON_TRACK,
  },
  {
    isPercentage: true,
    platform: SocialNetworks.LINKED_IN,
    kpi: "Avg. ER",
    month: 22.7,
    monthChange: -1,
    monthGoal: 8.5,
    goalDelta: -1,
    goalScore: GoalScoreType.OFF_TARGET,
  },
  {
    isPercentage: false,
    platform: SocialNetworks.WEBSITE,
    kpi: "Landing Page Sessions",
    month: 112938,
    monthChange: -6,
    monthGoal: 30952,
    goalScore: GoalScoreType.NA,
  },
  {
    isPercentage: false,
    platform: SocialNetworks.APP,
    kpi: "App Installs",
    month: 357,
    monthChange: 200,
    goalScore: GoalScoreType.NA,
  },
]

interface KPIData {
  id: string
  isPercentage: boolean
  platform: SocialNetworks
  kpi: string
  month: number
  monthChange: number
  monthGoal?: number
  goalDelta?: number
  goalScore: GoalScoreType
}

export const MonthlyKPIMockedDataYellowSquare: Omit<KPIData, "id">[] = [
  {
    isPercentage: true,
    platform: SocialNetworks.TIK_TOK,
    kpi: "Organic impressions",
    month: 2.37,
    monthChange: 52,
    monthGoal: 3,
    goalDelta: 5,
    goalScore: GoalScoreType.ON_TRACK,
  },
  {
    isPercentage: false,
    platform: SocialNetworks.TIK_TOK,
    kpi: "Organic Video Views",
    month: 248112,
    monthChange: -37,
    monthGoal: 100000,
    goalDelta: -4,
    goalScore: GoalScoreType.AT_RISK,
  },
  {
    isPercentage: false,
    platform: SocialNetworks.INSTAGRAM,
    kpi: "Organic engagements",
    month: 248112,
    monthChange: -37,
    monthGoal: 100000,
    goalDelta: -4,
    goalScore: GoalScoreType.OFF_TARGET,
  },
  {
    isPercentage: false,
    platform: SocialNetworks.INSTAGRAM,
    kpi: "Organic engagement Rate",
    month: 248112,
    monthChange: -37,
    monthGoal: 100000,
    goalDelta: -4,
    goalScore: GoalScoreType.OFF_TARGET,
  },
  {
    isPercentage: false,
    platform: SocialNetworks.APP,
    kpi: "Total Followers",
    month: 1564,
    monthChange: 116,
    monthGoal: 1500,
    goalDelta: 8,
    goalScore: GoalScoreType.ON_TRACK,
  },
  {
    isPercentage: true,
    platform: SocialNetworks.LINKED_IN,
    kpi: "New Followers",
    month: 22.7,
    monthChange: -1,
    monthGoal: 8.5,
    goalDelta: -1,
    goalScore: GoalScoreType.OFF_TARGET,
  },
  {
    isPercentage: false,
    platform: SocialNetworks.WEBSITE,
    kpi: "Profile Page Visits",
    month: 112938,
    monthChange: -6,
    monthGoal: 30952,
    goalScore: GoalScoreType.NA,
  },
  {
    isPercentage: false,
    platform: SocialNetworks.APP,
    kpi: "Site Visits",
    month: 357,
    monthChange: 200,
    goalScore: GoalScoreType.NA,
  },
  {
    isPercentage: false,
    platform: SocialNetworks.APP,
    kpi: "Site Bookings #",
    month: 357,
    monthChange: 200,
    goalScore: GoalScoreType.NA,
  },
  {
    isPercentage: false,
    platform: SocialNetworks.APP,
    kpi: "Bookings Revenue",
    month: 357,
    monthChange: 200,
    goalScore: GoalScoreType.NA,
  },
]

const getRandomSimilarValue = (value: number): number => {
  const variation = Math.random() * 0.2 // Variation of 20%
  const sign = Math.random() < 0.5 ? -1 : 1
  return value + sign * value * variation
}

const duplicateData = (data: Omit<KPIData, "id">[], id: number): KPIData[] => {
  return data.map((item) => ({
    ...item,
    id: id.toString(),
    month: getRandomSimilarValue(item.month),
    monthChange: getRandomSimilarValue(item.monthChange),
    monthGoal:
      item.monthGoal !== undefined
        ? getRandomSimilarValue(item.monthGoal)
        : undefined,
    goalDelta:
      item.goalDelta !== undefined
        ? getRandomSimilarValue(item.goalDelta)
        : undefined,
  }))
}

export const generateAllMonthlyData = (
  data: Omit<KPIData, "id">[],
): KPIData[] => {
  let allData: KPIData[] = []
  for (let id = 1; id <= 1; id++) {
    allData = allData.concat(duplicateData(data, id))
  }
  return allData
}
