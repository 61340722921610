import MetricBadge from "../../../../app/shared/MetricBadge"
import { FunnelRow } from "./rows"
import styles from "./index.module.scss"
import Loading from "../../../../app/shared/Loading"
import { HomeFunnelMetrics } from "../../homeTypes"

const BaseItem = ({
  value,
  difference,
  isLoading,
}: {
  value?: string
  difference?: number
  isLoading: boolean
}) => (
  <div className={styles.baseItem}>
    {isLoading ? (
      <Loading />
    ) : (
      <>
        <p className={styles.value}>{value ? value : "--"}</p>
        {difference !== undefined && difference !== null && (
          <MetricBadge numericValue={difference} isPercentage />
        )}
      </>
    )}
  </div>
)

const BaseTable = ({
  isLoading,
  homeMetrics = [
    { values: [{}, {}, {}] },
    { values: [{}, {}, {}] },
    { values: [{}, {}, {}] },
    { values: [{}, {}, {}] },
  ],
}: {
  isLoading: boolean
  homeMetrics?: HomeFunnelMetrics[]
}) => (
  <>
    {homeMetrics?.map((metric, index) => (
      <FunnelRow position={index} title={metric?.title ?? ""} key={index}>
        {[metric.values[1], metric.values[2], metric.values[0]].map(
          (metricValue, index) => (
            <BaseItem
              difference={metricValue.difference}
              value={metricValue.value?.toString()}
              isLoading={isLoading}
              key={`${metricValue.difference}${index}`}
            />
          ),
        )}
      </FunnelRow>
    ))}
  </>
)

export default BaseTable
