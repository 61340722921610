import { FC } from "react"
import Select, { SelectOption } from "../../../../app/shared/Select"
import styles from "./index.module.scss"
import { SocialNetworks } from "../../types/social-networks.enum"
import { getSocialImage } from "../../utils/getSocialImage"

const SOCIAL_OPTIONS: SelectOption[] = Object.values(SocialNetworks).map(
  (social) => ({ label: social, value: social }),
)

interface Props {
  social: SocialNetworks
  onSocialChange: (newValue: SocialNetworks) => void
}
const SocialSelector: FC<Props> = ({ social, onSocialChange }) => {
  const handleSocialSelectionChange = (selection: SelectOption) => {
    onSocialChange(selection.value as SocialNetworks)
  }

  return (
    <Select
      getIconFunction={(social) => getSocialImage(social as SocialNetworks)}
      selectedOption={{ label: social, value: social }}
      onClickOption={handleSocialSelectionChange}
      options={SOCIAL_OPTIONS}
      className={styles.selectSocialContainer}
      selectClassName={styles.selector}
    />
  )
}

export default SocialSelector
