import axios from "axios"
import storage from "./app/storage"

export const initAPI = () => {
  const API_URL = import.meta.env.VITE_API_URL
  axios.defaults.baseURL = API_URL
  axios.defaults.headers.common = {
    Accept: "application/json",
  }

  const accessToken = storage.getAccessToken()?.replaceAll('"', "")
  if (accessToken) {
    axios.defaults.headers.common = {
      Authorization: "Bearer " + accessToken,
    }
  }

  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response?.status === 401) {
        storage.setAccessToken(undefined)
      }
      return Promise.reject(error.response ? error.response : error)
    },
  )
}
