import { Link } from "react-router-dom"
import { useEffect } from "react"

import Breadcrumb from "../../../app/shared/Breadcrumb"
import SelectDate from "../../../app/shared/SelectDate/SelectDate"
import { HISTORICAL_CHARTS, SOCIAL } from "../../../routes"
import { AppImages } from "../../../app/appImages"
import HistoricalGraph from "./HistoricalGraph"
import DownloadImageButton from "../../../app/shared/DownloadImageButton"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import {
  getHistoricalCharts,
  selectHistoricalCharts,
  selectMonth,
  selectSocialStatus,
  selectYear,
  setMonth,
  setYear,
} from "./historicalSlice"
import { SliceStatus } from "../../../app/sliceStatus"
import Loading from "../../../app/shared/Loading"

import styles from "./index.module.scss"

const HistoricalChartsScreen = () => {
  const historicalCharts = useAppSelector(selectHistoricalCharts)
  const dispatch = useAppDispatch()
  const isLoading = useAppSelector(selectSocialStatus) === SliceStatus.loading
  const selectedMonth = useAppSelector(selectMonth)
  const selectedYear = useAppSelector(selectYear)

  useEffect(() => {
    dispatch(getHistoricalCharts({ month: selectedMonth, year: selectedYear }))
  }, [selectedYear, selectedMonth])

  return (
    <div className={styles.social}>
      <section className={styles.header}>
        <h1 className={styles.title}>Social</h1>
        <SelectDate
          month={selectedMonth}
          year={selectedYear}
          onMonthChange={(month) => dispatch(setMonth(month))}
          onYearChange={(year) => dispatch(setYear(year))}
        />
      </section>

      <section>
        <Breadcrumb
          items={[
            <Link to={SOCIAL}>
              <img src={AppImages.graySocialIcon} alt="social icon" />
            </Link>,
            <Link to={`${SOCIAL}${HISTORICAL_CHARTS}`}>Historical Charts</Link>,
          ]}
        />
      </section>

      {isLoading ? (
        <div className={`${styles.loading} ${styles.historicalGraph}`}>
          <Loading />
          <p>Loading...</p>
        </div>
      ) : (
        <>
          {Object.entries(historicalCharts).map(([metricName, chartData]) => (
            <section className={styles.summary} key={metricName}>
              <div className={styles.header}>
                <div className={styles.titleContainer}>
                  <h2 className={styles.title}>{metricName}</h2>
                  <DownloadImageButton
                    id={metricName}
                    fileName={`social-historical-${metricName}`}
                  />
                </div>
              </div>
              <HistoricalGraph
                chartDatasets={chartData}
                metricName={metricName}
              />
            </section>
          ))}
        </>
      )}
    </div>
  )
}

export default HistoricalChartsScreen
