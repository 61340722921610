import { SocialNetworks } from "../types/social-networks.enum"

export const HistoricalChartsMockedData = [
  {
    provider: SocialNetworks.LINKED_IN,
    data: [450, 300, 550, 600, 250, 200, 150, 500, 650, 400, 300, 100, 150],
  },
  {
    provider: SocialNetworks.INSTAGRAM,
    data: [200, 600, 550, 350, 400, 500, 100, 700, 300, 150, 450, 250, 650],
  },
  {
    provider: SocialNetworks.TIK_TOK,
    data: [450, 550, 650, 550, 750, 300, 500, 150, 300, 450, 600, 200, 350],
  },
]
