import { FC } from "react"
import Select, { SelectOption } from "../Select"
import styles from "./index.module.scss"

interface Props {
  options: SelectOption[]
  account: SelectOption
  onAccountChange: (newValue: string) => void
}
const AccountSelector: FC<Props> = ({ options, account, onAccountChange }) => {
  const handleAccountSelectionChange = (selection: SelectOption) => {
    onAccountChange(selection.value as string)
  }

  return (
    <Select
      selectedOption={{ label: account.label, value: account.value }}
      onClickOption={handleAccountSelectionChange}
      options={options}
      className={styles.selectSocialContainer}
      selectClassName={styles.selector}
    />
  )
}

export default AccountSelector
