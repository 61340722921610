import { FC, useMemo } from "react"

import styles from "./index.module.scss"
import { AppImages } from "../../../../../app/appImages"

interface TablePaginationProps {
  pageCount: number
  currentPage: number
  onPageChange: (page: number) => void
}

export const TablePagination: FC<TablePaginationProps> = ({
  pageCount,
  currentPage,
  onPageChange,
}) => {
  const pagesToRender = useMemo(() => {
    const array: (number | string)[] = []
    if (pageCount <= 5) {
      for (let i = 1; i <= pageCount; i++) {
        array.push(i)
      }
    } else {
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          array.push(i)
        }
        for (
          let i = Math.max(currentPage + 1, 4);
          i <= Math.min(currentPage + 2, pageCount - 2);
          i++
        ) {
          array.push(i)
        }
        if (currentPage < pageCount - 4) array.push("...")

        array.push(...[pageCount - 2, pageCount - 1, pageCount])
      } else if (currentPage >= pageCount - 2) {
        array.push(...[1, 2, 3])
        array.push("...")
        for (let i = pageCount - 4; i <= pageCount; i++) {
          array.push(i)
        }
      } else {
        array.push(...[1, 2, 3])
        if (currentPage >= 6) array.push("...")
        for (
          let i = Math.max(currentPage - 2, 4);
          i <= Math.min(currentPage + 2, pageCount - 3);
          i++
        ) {
          array.push(i)
        }
        if (currentPage < pageCount - 4) array.push("...")

        array.push(...[pageCount - 2, pageCount - 1, pageCount])
      }
    }
    return array
  }, [pageCount, currentPage])

  return (
    <div className={styles.pagination}>
      <PaginationArrowButton
        disabled={currentPage <= 1}
        onClick={() => onPageChange(currentPage - 1)}
        arrowButton={
          <span className={styles.arrow_button}>
            <img src={AppImages.leftArrowIcon} alt="Previous arrow" />
            Previous
          </span>
        }
      />
      {pagesToRender.map((page, index) => {
        if (typeof page === "number")
          return (
            <PaginationButton
              value={page}
              key={index}
              onClick={() => onPageChange(page)}
              isActive={currentPage === page}
            />
          )
        else return <p key={index}>{page}</p>
      })}
      <PaginationArrowButton
        disabled={currentPage >= pageCount}
        onClick={() => onPageChange(currentPage + 1)}
        arrowButton={
          <span className={styles.arrow_button}>
            Next
            <img
              src={AppImages.leftArrowIcon}
              alt="Next arrow"
              className={styles.right_arrow}
            ></img>
          </span>
        }
      />
    </div>
  )
}

interface PaginationButtonProps {
  value: number | string
  onClick: (value: number | string) => void
  isActive?: boolean
}

const PaginationButton: FC<PaginationButtonProps> = ({
  value,
  onClick,
  isActive,
}) => {
  return (
    <button
      onClick={() => onClick(value)}
      className={`${styles.pagination_button} ${
        isActive ? styles.isActive : ""
      }`}
    >
      {value}
    </button>
  )
}

interface PaginationArrowButtonProps {
  arrowButton: JSX.Element
  onClick: () => void
  disabled?: boolean
}

const PaginationArrowButton: FC<PaginationArrowButtonProps> = ({
  arrowButton,
  onClick,
  disabled,
}) => {
  return (
    <button
      onClick={onClick}
      className={styles.pagination_button}
      disabled={disabled}
    >
      {arrowButton}
    </button>
  )
}
