import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"

import facebookAPI from "../facebookAPI"
import { HistoricalDatasetChartType } from "./historicalTypes"
import { SelectOption } from "../../../app/shared/Select"
import { SliceStatus } from "../../../app/sliceStatus"
import { RootState } from "../../../app/store"

interface FacebookHistoricalChartState {
  month: SelectOption
  year: SelectOption
  charts: HistoricalDatasetChartType
  status: SliceStatus
  account?: string
}

const initialState: FacebookHistoricalChartState = {
  month: { value: 1, label: "January" },
  year: { value: 2023, label: "2023" },
  charts: {},
  status: SliceStatus.idle,
  account: undefined,
}

export const getHistoricalCharts = createAsyncThunk(
  "facebookHistoricalCharts/getHistoricalCharts",
  async (
    {
      month,
      year,
      account,
    }: { month: SelectOption; year: SelectOption; account?: string },
    { dispatch },
  ) => {
    dispatch(setMonth(month))
    dispatch(setYear(year))
    const historicalMetrics = await facebookAPI.getHistoricalMetrics({
      month:
        Number(month.value) < 10
          ? `0${month.value.toString()}`
          : month.value.toString(),
      year: year.value.toString(),
      account,
    })

    return {
      historicalMetrics: historicalMetrics.data.charts,
    }
  },
)

export const facebookSlice = createSlice({
  name: "facebookHistoricalCharts",
  initialState,
  reducers: {
    setMonth: (state, action: PayloadAction<SelectOption>) => {
      state.month = action.payload
    },
    setYear: (state, action: PayloadAction<SelectOption>) => {
      state.year = action.payload
    },
    setSelectedAccount: (state, action: PayloadAction<string | undefined>) => {
      state.account = action.payload
    },

    resetHistoricalCharts: (state) => {
      state.charts = initialState.charts
      state.status = initialState.status
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHistoricalCharts.pending, (state) => {
        state.status = SliceStatus.loading
      })
      .addCase(
        getHistoricalCharts.fulfilled,
        (
          state,
          action: PayloadAction<{
            historicalMetrics: HistoricalDatasetChartType
          }>,
        ) => {
          state.status = SliceStatus.loaded
          state.charts = action.payload?.historicalMetrics
        },
      )
      .addCase(getHistoricalCharts.rejected, (state) => {
        state.status = SliceStatus.failed
      })
  },
})

export const { setMonth, setYear, resetHistoricalCharts, setSelectedAccount } =
  facebookSlice.actions

export const selectHistoricalStatus = (state: RootState) =>
  state.facebookHistoricalCharts.status
export const selectAccount = (state: RootState) =>
  state.facebookHistoricalCharts.account
export const selectMonth = (state: RootState) =>
  state.facebookHistoricalCharts.month
export const selectYear = (state: RootState) =>
  state.facebookHistoricalCharts.year
export const selectHistoricalCharts = (state: RootState) =>
  state.facebookHistoricalCharts.charts

export default facebookSlice.reducer
