import { SocialNetworks } from "../types/social-networks.enum"

export const getPostDetailsMockedData = () => {
  const instagramData = [
    {
      date: "April 23, 2023 05:15pm",
      image: "/images/post-bba.jpeg",
      description: "Enjoying the sunny day at the beach! #sunnyday #beachvibes",
      metrics: [
        { metric: "Likes", value: 512 },
        { metric: "Comments", value: 24 },
        { metric: "Impressions", value: 8521 },
        { metric: "Reach", value: 7800 },
        { metric: "Eng. Rate", value: 6.12, isPercentage: true },
      ],
      provider: SocialNetworks.INSTAGRAM,
    },
    {
      date: "March 7, 2022 09:34am",
      image: "/images/post-bba.jpeg",
      description: "Had an amazing brunch with friends today! #brunch #friends",
      metrics: [
        { metric: "Likes", value: 389 },
        { metric: "Comments", value: 15 },
        { metric: "Impressions", value: 7320 },
        { metric: "Reach", value: 6900 },
        { metric: "Eng. Rate", value: 5.78, isPercentage: true },
      ],
      provider: SocialNetworks.INSTAGRAM,
    },
    {
      date: "July 14, 2022 12:48pm",
      image: "/images/post-bba.jpeg",
      description:
        "Exploring the city! Found some amazing street art. #cityexploration #streetart",
      metrics: [
        { metric: "Likes", value: 620 },
        { metric: "Comments", value: 30 },
        { metric: "Impressions", value: 9400 },
        { metric: "Reach", value: 8900 },
        { metric: "Eng. Rate", value: 6.96, isPercentage: true },
      ],
      provider: SocialNetworks.INSTAGRAM,
    },
    {
      date: "October 1, 2022 07:22pm",
      image: "/images/post-bba.jpeg",
      description: "Coffee time at my favorite spot. #coffee #relax",
      metrics: [
        { metric: "Likes", value: 450 },
        { metric: "Comments", value: 18 },
        { metric: "Impressions", value: 8000 },
        { metric: "Reach", value: 7500 },
        { metric: "Eng. Rate", value: 6.0, isPercentage: true },
      ],
      provider: SocialNetworks.INSTAGRAM,
    },
    {
      date: "December 11, 2022 08:37am",
      image: "/images/post-bba.jpeg",
      description: "Sunset views are the best! #sunset #nature",
      metrics: [
        { metric: "Likes", value: 580 },
        { metric: "Comments", value: 22 },
        { metric: "Impressions", value: 8600 },
        { metric: "Reach", value: 8100 },
        { metric: "Eng. Rate", value: 7.16, isPercentage: true },
      ],
      provider: SocialNetworks.INSTAGRAM,
    },
    {
      date: "February 15, 2023 04:29pm",
      image: "/images/post-bba.jpeg",
      description: "Workout session complete! Feeling great. #fitness #workout",
      metrics: [
        { metric: "Likes", value: 420 },
        { metric: "Comments", value: 20 },
        { metric: "Impressions", value: 7800 },
        { metric: "Reach", value: 7300 },
        { metric: "Eng. Rate", value: 5.75, isPercentage: true },
      ],
      provider: SocialNetworks.INSTAGRAM,
    },
    {
      date: "March 23, 2023 11:47am",
      image: "/images/post-bba.jpeg",
      description: "Delicious homemade dinner tonight! #homemade #foodie",
      metrics: [
        { metric: "Likes", value: 540 },
        { metric: "Comments", value: 25 },
        { metric: "Impressions", value: 8500 },
        { metric: "Reach", value: 8000 },
        { metric: "Eng. Rate", value: 6.75, isPercentage: true },
      ],
      provider: SocialNetworks.INSTAGRAM,
    },
    {
      date: "May 5, 2023 02:18pm",
      image: "/images/post-bba.jpeg",
      description: "Weekend getaway to the mountains. #weekend #getaway",
      metrics: [
        { metric: "Likes", value: 610 },
        { metric: "Comments", value: 28 },
        { metric: "Impressions", value: 9000 },
        { metric: "Reach", value: 8500 },
        { metric: "Eng. Rate", value: 7.18, isPercentage: true },
      ],
      provider: SocialNetworks.INSTAGRAM,
    },
    {
      date: "July 12, 2023 05:05pm",
      image: "/images/post-bba.jpeg",
      description: "Spring is here! Loving the flowers. #spring #flowers",
      metrics: [
        { metric: "Likes", value: 480 },
        { metric: "Comments", value: 19 },
        { metric: "Impressions", value: 8200 },
        { metric: "Reach", value: 7700 },
        { metric: "Eng. Rate", value: 6.23, isPercentage: true },
      ],
      provider: SocialNetworks.INSTAGRAM,
    },
    {
      date: "September 28, 2023 06:59pm",
      image: "/images/post-bba.jpeg",
      description: "Road trip adventures! #roadtrip #travel",
      metrics: [
        { metric: "Likes", value: 570 },
        { metric: "Comments", value: 27 },
        { metric: "Impressions", value: 8800 },
        { metric: "Reach", value: 8300 },
        { metric: "Eng. Rate", value: 6.87, isPercentage: true },
      ],
      provider: SocialNetworks.INSTAGRAM,
    },
    {
      date: "November 3, 2023 01:44pm",
      image: "/images/post-bba.jpeg",
      description: "A day at the museum. #art #museum",
      metrics: [
        { metric: "Likes", value: 530 },
        { metric: "Comments", value: 22 },
        { metric: "Impressions", value: 8400 },
        { metric: "Reach", value: 7900 },
        { metric: "Eng. Rate", value: 6.71, isPercentage: true },
      ],
      provider: SocialNetworks.INSTAGRAM,
    },
    {
      date: "January 16, 2024 03:33pm",
      image: "/images/post-bba.jpeg",
      description: "Relaxing with a good book. #reading #relax",
      metrics: [
        { metric: "Likes", value: 490 },
        { metric: "Comments", value: 21 },
        { metric: "Impressions", value: 8100 },
        { metric: "Reach", value: 7600 },
        { metric: "Eng. Rate", value: 6.45, isPercentage: true },
      ],
      provider: SocialNetworks.INSTAGRAM,
    },
    {
      date: "March 2, 2024 07:12am",
      image: "/images/post-bba.jpeg",
      description: "Cooking up a storm in the kitchen. #cooking #food",
      metrics: [
        { metric: "Likes", value: 460 },
        { metric: "Comments", value: 20 },
        { metric: "Impressions", value: 7900 },
        { metric: "Reach", value: 7400 },
        { metric: "Eng. Rate", value: 6.22, isPercentage: true },
      ],
      provider: SocialNetworks.INSTAGRAM,
    },
    {
      date: "April 5, 2024 09:25pm",
      image: "/images/post-bba.jpeg",
      description: "Exploring new trails today. #hiking #nature",
      metrics: [
        { metric: "Likes", value: 550 },
        { metric: "Comments", value: 23 },
        { metric: "Impressions", value: 8700 },
        { metric: "Reach", value: 8200 },
        { metric: "Eng. Rate", value: 6.71, isPercentage: true },
      ],
      provider: SocialNetworks.INSTAGRAM,
    },
    {
      date: "May 10, 2024 11:45am",
      image: "/images/post-bba.jpeg",
      description: "Night out with friends! #nightout #fun",
      metrics: [
        { metric: "Likes", value: 600 },
        { metric: "Comments", value: 25 },
        { metric: "Impressions", value: 9000 },
        { metric: "Reach", value: 8500 },
        { metric: "Eng. Rate", value: 7.06, isPercentage: true },
      ],
      provider: SocialNetworks.INSTAGRAM,
    },
    {
      date: "June 18, 2023 10:22am",
      image: "/images/post-bba.jpeg",
      description: "Trying out new recipes! #cooking #newrecipe",
      metrics: [
        { metric: "Likes", value: 490 },
        { metric: "Comments", value: 19 },
        { metric: "Impressions", value: 8300 },
        { metric: "Reach", value: 7800 },
        { metric: "Eng. Rate", value: 6.28, isPercentage: true },
      ],
      provider: SocialNetworks.INSTAGRAM,
    },
    {
      date: "July 24, 2022 08:15pm",
      image: "/images/post-bba.jpeg",
      description: "Visiting family for the weekend. #family #weekend",
      metrics: [
        { metric: "Likes", value: 520 },
        { metric: "Comments", value: 24 },
        { metric: "Impressions", value: 8500 },
        { metric: "Reach", value: 8000 },
        { metric: "Eng. Rate", value: 6.5, isPercentage: true },
      ],
      provider: SocialNetworks.INSTAGRAM,
    },
    {
      date: "August 8, 2023 09:49am",
      image: "/images/post-bba.jpeg",
      description: "Morning yoga to start the day. #yoga #morningroutine",
      metrics: [
        { metric: "Likes", value: 460 },
        { metric: "Comments", value: 20 },
        { metric: "Impressions", value: 8000 },
        { metric: "Reach", value: 7500 },
        { metric: "Eng. Rate", value: 6.13, isPercentage: true },
      ],
      provider: SocialNetworks.INSTAGRAM,
    },
    {
      date: "September 17, 2023 02:54pm",
      image: "/images/post-bba.jpeg",
      description: "Spent the day at the zoo. #zoo #animals",
      metrics: [
        { metric: "Likes", value: 500 },
        { metric: "Comments", value: 21 },
        { metric: "Impressions", value: 8300 },
        { metric: "Reach", value: 7800 },
        { metric: "Eng. Rate", value: 6.41, isPercentage: true },
      ],
      provider: SocialNetworks.INSTAGRAM,
    },
    {
      date: "October 12, 2022 04:03pm",
      image: "/images/post-bba.jpeg",
      description: "Learning a new hobby! #newhobby #fun",
      metrics: [
        { metric: "Likes", value: 470 },
        { metric: "Comments", value: 19 },
        { metric: "Impressions", value: 8100 },
        { metric: "Reach", value: 7600 },
        { metric: "Eng. Rate", value: 6.18, isPercentage: true },
      ],
      provider: SocialNetworks.INSTAGRAM,
    },
    {
      date: "November 19, 2022 07:22am",
      image: "/images/post-bba.jpeg",
      description: "Enjoying a quiet morning. #morning #peaceful",
      metrics: [
        { metric: "Likes", value: 530 },
        { metric: "Comments", value: 22 },
        { metric: "Impressions", value: 8400 },
        { metric: "Reach", value: 7900 },
        { metric: "Eng. Rate", value: 6.71, isPercentage: true },
      ],
      provider: SocialNetworks.INSTAGRAM,
    },
    {
      date: "December 5, 2022 08:19am",
      image: "/images/post-bba.jpeg",
      description: "Afternoon stroll in the park. #park #relaxing",
      metrics: [
        { metric: "Likes", value: 490 },
        { metric: "Comments", value: 20 },
        { metric: "Impressions", value: 8100 },
        { metric: "Reach", value: 7600 },
        { metric: "Eng. Rate", value: 6.45, isPercentage: true },
      ],
      provider: SocialNetworks.INSTAGRAM,
    },
  ]

  const tikTokData = [
    {
      date: "February 5, 2024 03:45pm",
      image: "/images/post-bba.jpeg",
      description:
        "Had an amazing day exploring the city! #TravelDiaries #Wanderlust",
      metrics: [
        { metric: "Likes", value: 315 },
        { metric: "Comments", value: 12 },
        { metric: "Impressions", value: 7697 },
        { metric: "Reach", value: 6986 },
        { metric: "Eng. Rate", value: 6.29, isPercentage: true },
      ],
      provider: SocialNetworks.TIK_TOK,
    },
    {
      date: "January 20, 2023 08:12am",
      image: "/images/post-bba.jpeg",
      description:
        "Starting the morning with a great workout! 💪 #FitnessGoals",
      metrics: [
        { metric: "Likes", value: 354 },
        { metric: "Comments", value: 8 },
        { metric: "Impressions", value: 8134 },
        { metric: "Reach", value: 7320 },
        { metric: "Eng. Rate", value: 7.1, isPercentage: true },
      ],
      provider: SocialNetworks.TIK_TOK,
    },
    {
      date: "March 14, 2023 06:30pm",
      image: "/images/post-bba.jpeg",
      description:
        "Delicious homemade dinner tonight. Who else loves cooking? #Foodie",
      metrics: [
        { metric: "Likes", value: 295 },
        { metric: "Comments", value: 15 },
        { metric: "Impressions", value: 7400 },
        { metric: "Reach", value: 6750 },
        { metric: "Eng. Rate", value: 5.8, isPercentage: true },
      ],
      provider: SocialNetworks.TIK_TOK,
    },
    {
      date: "August 9, 2023 09:50am",
      image: "/images/post-bba.jpeg",
      description:
        "Exploring new hiking trails today. Nature is so refreshing! #HikingAdventures",
      metrics: [
        { metric: "Likes", value: 422 },
        { metric: "Comments", value: 20 },
        { metric: "Impressions", value: 8670 },
        { metric: "Reach", value: 7950 },
        { metric: "Eng. Rate", value: 6.5, isPercentage: true },
      ],
      provider: SocialNetworks.TIK_TOK,
    },
    {
      date: "November 3, 2023 01:22pm",
      image: "/images/post-bba.jpeg",
      description:
        "Cozy afternoon with a good book and some tea. Perfect way to relax. #BookLovers",
      metrics: [
        { metric: "Likes", value: 388 },
        { metric: "Comments", value: 14 },
        { metric: "Impressions", value: 8200 },
        { metric: "Reach", value: 7500 },
        { metric: "Eng. Rate", value: 6.1, isPercentage: true },
      ],
      provider: SocialNetworks.TIK_TOK,
    },
    {
      date: "May 17, 2023 10:30am",
      image: "/images/post-bba.jpeg",
      description:
        "Just finished a beautiful painting. Art is my therapy. 🎨 #ArtisticVibes",
      metrics: [
        { metric: "Likes", value: 412 },
        { metric: "Comments", value: 18 },
        { metric: "Impressions", value: 8600 },
        { metric: "Reach", value: 7800 },
        { metric: "Eng. Rate", value: 6.4, isPercentage: true },
      ],
      provider: SocialNetworks.TIK_TOK,
    },
    {
      date: "June 23, 2023 07:15pm",
      image: "/images/post-bba.jpeg",
      description:
        "Beach day! Nothing beats the sound of waves and the feeling of sand between your toes. 🏖️ #BeachLife",
      metrics: [
        { metric: "Likes", value: 425 },
        { metric: "Comments", value: 22 },
        { metric: "Impressions", value: 8700 },
        { metric: "Reach", value: 8000 },
        { metric: "Eng. Rate", value: 6.6, isPercentage: true },
      ],
      provider: SocialNetworks.TIK_TOK,
    },
    {
      date: "September 11, 2022 04:30pm",
      image: "/images/post-bba.jpeg",
      description: "Enjoying a beautiful sunset with friends. #SunsetLovers",
      metrics: [
        { metric: "Likes", value: 390 },
        { metric: "Comments", value: 17 },
        { metric: "Impressions", value: 8350 },
        { metric: "Reach", value: 7600 },
        { metric: "Eng. Rate", value: 6.2, isPercentage: true },
      ],
      provider: SocialNetworks.TIK_TOK,
    },
    {
      date: "July 5, 2023 11:45am",
      image: "/images/post-bba.jpeg",
      description:
        "A day full of adventures and new experiences. Can't wait to share more! #Adventures",
      metrics: [
        { metric: "Likes", value: 370 },
        { metric: "Comments", value: 12 },
        { metric: "Impressions", value: 8050 },
        { metric: "Reach", value: 7300 },
        { metric: "Eng. Rate", value: 6.0, isPercentage: true },
      ],
      provider: SocialNetworks.TIK_TOK,
    },
    {
      date: "October 29, 2023 02:30pm",
      image: "/images/post-bba.jpeg",
      description:
        "Spending quality time with family. Cherish these moments. ❤️ #FamilyTime",
      metrics: [
        { metric: "Likes", value: 408 },
        { metric: "Comments", value: 16 },
        { metric: "Impressions", value: 8450 },
        { metric: "Reach", value: 7750 },
        { metric: "Eng. Rate", value: 6.3, isPercentage: true },
      ],
      provider: SocialNetworks.TIK_TOK,
    },
    {
      date: "December 25, 2023 09:00am",
      image: "/images/post-bba.jpeg",
      description:
        "Merry Christmas everyone! Hope you're all having a wonderful day. 🎄 #ChristmasJoy",
      metrics: [
        { metric: "Likes", value: 450 },
        { metric: "Comments", value: 25 },
        { metric: "Impressions", value: 8900 },
        { metric: "Reach", value: 8200 },
        { metric: "Eng. Rate", value: 6.8, isPercentage: true },
      ],
      provider: SocialNetworks.TIK_TOK,
    },
    {
      date: "January 15, 2024 08:20am",
      image: "/images/post-bba.jpeg",
      description: "New year, new goals! Ready to take on 2024. #NewYearGoals",
      metrics: [
        { metric: "Likes", value: 460 },
        { metric: "Comments", value: 28 },
        { metric: "Impressions", value: 9000 },
        { metric: "Reach", value: 8300 },
        { metric: "Eng. Rate", value: 7.0, isPercentage: true },
      ],
      provider: SocialNetworks.TIK_TOK,
    },
    {
      date: "March 7, 2024 05:00pm",
      image: "/images/post-bba.jpeg",
      description:
        "Can't believe it's already March! Time flies when you're having fun. #TimeFlies",
      metrics: [
        { metric: "Likes", value: 480 },
        { metric: "Comments", value: 30 },
        { metric: "Impressions", value: 9200 },
        { metric: "Reach", value: 8500 },
        { metric: "Eng. Rate", value: 7.2, isPercentage: true },
      ],
      provider: SocialNetworks.TIK_TOK,
    },
    {
      date: "March 1, 2023 01:00pm",
      image: "/images/post-bba.jpeg",
      description:
        "Another day, another adventure! Loving this journey. #TravelLife",
      metrics: [
        { metric: "Likes", value: 470 },
        { metric: "Comments", value: 25 },
        { metric: "Impressions", value: 9100 },
        { metric: "Reach", value: 8400 },
        { metric: "Eng. Rate", value: 7.0, isPercentage: true },
      ],
      provider: SocialNetworks.TIK_TOK,
    },
    {
      date: "April 12, 2023 06:45pm",
      image: "/images/post-bba.jpeg",
      description: "Evening strolls are the best. 🌇 #EveningWalks",
      metrics: [
        { metric: "Likes", value: 465 },
        { metric: "Comments", value: 27 },
        { metric: "Impressions", value: 9050 },
        { metric: "Reach", value: 8350 },
        { metric: "Eng. Rate", value: 6.9, isPercentage: true },
      ],
      provider: SocialNetworks.TIK_TOK,
    },
    {
      date: "October 3, 2022 10:00am",
      image: "/images/post-bba.jpeg",
      description:
        "Pumpkin spice everything! Loving the fall vibes. 🍁 #FallSeason",
      metrics: [
        { metric: "Likes", value: 375 },
        { metric: "Comments", value: 10 },
        { metric: "Impressions", value: 8150 },
        { metric: "Reach", value: 7350 },
        { metric: "Eng. Rate", value: 6.1, isPercentage: true },
      ],
      provider: SocialNetworks.TIK_TOK,
    },
    {
      date: "July 23, 2022 03:30pm",
      image: "/images/post-bba.jpeg",
      description: "Summer days are the best days. ☀️ #SummerFun",
      metrics: [
        { metric: "Likes", value: 380 },
        { metric: "Comments", value: 11 },
        { metric: "Impressions", value: 8200 },
        { metric: "Reach", value: 7400 },
        { metric: "Eng. Rate", value: 6.2, isPercentage: true },
      ],
      provider: SocialNetworks.TIK_TOK,
    },
    {
      date: "November 11, 2023 12:00pm",
      image: "/images/post-bba.jpeg",
      description: "Feeling grateful for everything in life. 🙏 #Gratitude",
      metrics: [
        { metric: "Likes", value: 385 },
        { metric: "Comments", value: 13 },
        { metric: "Impressions", value: 8250 },
        { metric: "Reach", value: 7450 },
        { metric: "Eng. Rate", value: 6.3, isPercentage: true },
      ],
      provider: SocialNetworks.TIK_TOK,
    },
    {
      date: "May 8, 2023 09:00am",
      image: "/images/post-bba.jpeg",
      description:
        "Starting the week with a positive mindset. #MondayMotivation",
      metrics: [
        { metric: "Likes", value: 390 },
        { metric: "Comments", value: 14 },
        { metric: "Impressions", value: 8300 },
        { metric: "Reach", value: 7500 },
        { metric: "Eng. Rate", value: 6.4, isPercentage: true },
      ],
      provider: SocialNetworks.TIK_TOK,
    },
    {
      date: "August 15, 2023 07:30pm",
      image: "/images/post-bba.jpeg",
      description: "Summer nights are magical. ✨ #SummerNights",
      metrics: [
        { metric: "Likes", value: 395 },
        { metric: "Comments", value: 15 },
        { metric: "Impressions", value: 8350 },
        { metric: "Reach", value: 7550 },
        { metric: "Eng. Rate", value: 6.5, isPercentage: true },
      ],
      provider: SocialNetworks.TIK_TOK,
    },
    {
      date: "September 25, 2022 11:15am",
      image: "/images/post-bba.jpeg",
      description: "Catching up on some reading today. 📚 #BookWorm",
      metrics: [
        { metric: "Likes", value: 400 },
        { metric: "Comments", value: 16 },
        { metric: "Impressions", value: 8400 },
        { metric: "Reach", value: 7600 },
        { metric: "Eng. Rate", value: 6.6, isPercentage: true },
      ],
      provider: SocialNetworks.TIK_TOK,
    },
    {
      date: "January 1, 2023 12:00pm",
      image: "/images/post-bba.jpeg",
      description:
        "Happy New Year! Wishing everyone a fantastic 2023. 🎉 #NewYear",
      metrics: [
        { metric: "Likes", value: 405 },
        { metric: "Comments", value: 18 },
        { metric: "Impressions", value: 8450 },
        { metric: "Reach", value: 7650 },
        { metric: "Eng. Rate", value: 6.7, isPercentage: true },
      ],
      provider: SocialNetworks.TIK_TOK,
    },
    {
      date: "December 10, 2022 05:30pm",
      image: "/images/post-bba.jpeg",
      description: "Getting into the holiday spirit! 🎅 #HolidaySeason",
      metrics: [
        { metric: "Likes", value: 410 },
        { metric: "Comments", value: 20 },
        { metric: "Impressions", value: 8500 },
        { metric: "Reach", value: 7700 },
        { metric: "Eng. Rate", value: 6.8, isPercentage: true },
      ],
      provider: SocialNetworks.TIK_TOK,
    },
  ]

  const linkedInData = [
    {
      date: "February 10, 2023 03:45pm",
      image: "/images/post-bba.jpeg",
      description:
        "Had a great time networking with industry leaders at the annual tech conference. #Networking #TechConference",
      metrics: [
        { metric: "Likes", value: 315 },
        { metric: "Comments", value: 12 },
        { metric: "Impressions", value: 7697 },
        { metric: "Reach", value: 6986 },
        { metric: "Eng. Rate", value: 6.29, isPercentage: true },
      ],
      provider: SocialNetworks.LINKED_IN,
    },
    {
      date: "April 25, 2024 09:15am",
      image: "/images/post-bba.jpeg",
      description:
        "Excited to announce our new project launch. Stay tuned for updates! #NewProject #Innovation",
      metrics: [
        { metric: "Likes", value: 415 },
        { metric: "Comments", value: 22 },
        { metric: "Impressions", value: 8697 },
        { metric: "Reach", value: 7986 },
        { metric: "Eng. Rate", value: 6.29, isPercentage: true },
      ],
      provider: SocialNetworks.LINKED_IN,
    },
    {
      date: "March 14, 2024 11:32am",
      image: "/images/post-bba.jpeg",
      description:
        "Thrilled to be part of the panel discussion on sustainable business practices. #Sustainability #Business",
      metrics: [
        { metric: "Likes", value: 330 },
        { metric: "Comments", value: 18 },
        { metric: "Impressions", value: 8000 },
        { metric: "Reach", value: 7200 },
        { metric: "Eng. Rate", value: 6.29, isPercentage: true },
      ],
      provider: SocialNetworks.LINKED_IN,
    },
    {
      date: "January 5, 2023 08:50am",
      image: "/images/post-bba.jpeg",
      description:
        "Amazing collaboration session with the team today. Exciting ideas flowing! #TeamWork #Collaboration",
      metrics: [
        { metric: "Likes", value: 295 },
        { metric: "Comments", value: 10 },
        { metric: "Impressions", value: 7500 },
        { metric: "Reach", value: 6800 },
        { metric: "Eng. Rate", value: 6.29, isPercentage: true },
      ],
      provider: SocialNetworks.LINKED_IN,
    },
    {
      date: "March 18, 2024 05:22pm",
      image: "/images/post-bba.jpeg",
      description:
        "Our latest blog post on digital transformation is now live. Check it out! #DigitalTransformation #Blog",
      metrics: [
        { metric: "Likes", value: 410 },
        { metric: "Comments", value: 14 },
        { metric: "Impressions", value: 8500 },
        { metric: "Reach", value: 7800 },
        { metric: "Eng. Rate", value: 6.29, isPercentage: true },
      ],
      provider: SocialNetworks.LINKED_IN,
    },
    {
      date: "May 3, 2023 02:30pm",
      image: "/images/post-bba.jpeg",
      description:
        "Participated in an insightful webinar on AI and its future impact. #AI #Webinar",
      metrics: [
        { metric: "Likes", value: 375 },
        { metric: "Comments", value: 15 },
        { metric: "Impressions", value: 8100 },
        { metric: "Reach", value: 7300 },
        { metric: "Eng. Rate", value: 6.29, isPercentage: true },
      ],
      provider: SocialNetworks.LINKED_IN,
    },
    {
      date: "July 22, 2023 06:00pm",
      image: "/images/post-bba.jpeg",
      description:
        "Celebrating our latest milestone with the team. Proud of our achievements! #Milestone #Success",
      metrics: [
        { metric: "Likes", value: 450 },
        { metric: "Comments", value: 25 },
        { metric: "Impressions", value: 9000 },
        { metric: "Reach", value: 8200 },
        { metric: "Eng. Rate", value: 6.29, isPercentage: true },
      ],
      provider: SocialNetworks.LINKED_IN,
    },
    {
      date: "November 15, 2023 01:10pm",
      image: "/images/post-bba.jpeg",
      description:
        "Great discussion on leadership and management at the workshop today. #Leadership #Management",
      metrics: [
        { metric: "Likes", value: 385 },
        { metric: "Comments", value: 20 },
        { metric: "Impressions", value: 8400 },
        { metric: "Reach", value: 7600 },
        { metric: "Eng. Rate", value: 6.29, isPercentage: true },
      ],
      provider: SocialNetworks.LINKED_IN,
    },
    {
      date: "August 30, 2023 10:00am",
      image: "/images/post-bba.jpeg",
      description:
        "Announcing our new partnership with leading industry experts. Exciting times ahead! #Partnership #Industry",
      metrics: [
        { metric: "Likes", value: 420 },
        { metric: "Comments", value: 19 },
        { metric: "Impressions", value: 8600 },
        { metric: "Reach", value: 7800 },
        { metric: "Eng. Rate", value: 6.29, isPercentage: true },
      ],
      provider: SocialNetworks.LINKED_IN,
    },
    {
      date: "October 12, 2023 04:45pm",
      image: "/images/post-bba.jpeg",
      description:
        "Just finished an amazing meeting with our new client. Looking forward to a fruitful collaboration! #ClientMeeting #Collaboration",
      metrics: [
        { metric: "Likes", value: 310 },
        { metric: "Comments", value: 13 },
        { metric: "Impressions", value: 7700 },
        { metric: "Reach", value: 7000 },
        { metric: "Eng. Rate", value: 6.29, isPercentage: true },
      ],
      provider: SocialNetworks.LINKED_IN,
    },
    {
      date: "December 9, 2023 08:30am",
      image: "/images/post-bba.jpeg",
      description:
        "Excited to share insights from my latest article on market trends. #MarketTrends #Insights",
      metrics: [
        { metric: "Likes", value: 360 },
        { metric: "Comments", value: 16 },
        { metric: "Impressions", value: 8200 },
        { metric: "Reach", value: 7400 },
        { metric: "Eng. Rate", value: 6.29, isPercentage: true },
      ],
      provider: SocialNetworks.LINKED_IN,
    },
    {
      date: "January 27, 2024 12:05pm",
      image: "/images/post-bba.jpeg",
      description:
        "Honored to be a speaker at the global entrepreneurship summit. #Entrepreneurship #Summit",
      metrics: [
        { metric: "Likes", value: 395 },
        { metric: "Comments", value: 17 },
        { metric: "Impressions", value: 8350 },
        { metric: "Reach", value: 7550 },
        { metric: "Eng. Rate", value: 6.29, isPercentage: true },
      ],
      provider: SocialNetworks.LINKED_IN,
    },
    {
      date: "September 5, 2023 07:40pm",
      image: "/images/post-bba.jpeg",
      description:
        "Reflecting on an incredible year of growth and achievements. Here's to more success ahead! #YearInReview #Success",
      metrics: [
        { metric: "Likes", value: 425 },
        { metric: "Comments", value: 21 },
        { metric: "Impressions", value: 8700 },
        { metric: "Reach", value: 7900 },
        { metric: "Eng. Rate", value: 6.29, isPercentage: true },
      ],
      provider: SocialNetworks.LINKED_IN,
    },
    {
      date: "April 8, 2023 09:50am",
      image: "/images/post-bba.jpeg",
      description:
        "Wonderful experience attending the leadership retreat this weekend. #LeadershipRetreat #Growth",
      metrics: [
        { metric: "Likes", value: 340 },
        { metric: "Comments", value: 18 },
        { metric: "Impressions", value: 8050 },
        { metric: "Reach", value: 7250 },
        { metric: "Eng. Rate", value: 6.29, isPercentage: true },
      ],
      provider: SocialNetworks.LINKED_IN,
    },
    {
      date: "February 21, 2023 03:30pm",
      image: "/images/post-bba.jpeg",
      description:
        "Excited to welcome our new team members! Looking forward to great things ahead. #NewTeam #Growth",
      metrics: [
        { metric: "Likes", value: 355 },
        { metric: "Comments", value: 15 },
        { metric: "Impressions", value: 8150 },
        { metric: "Reach", value: 7350 },
        { metric: "Eng. Rate", value: 6.29, isPercentage: true },
      ],
      provider: SocialNetworks.LINKED_IN,
    },
    {
      date: "October 18, 2023 11:20am",
      image: "/images/post-bba.jpeg",
      description:
        "Participated in an inspiring workshop on diversity and inclusion in the workplace. #Diversity #Inclusion",
      metrics: [
        { metric: "Likes", value: 370 },
        { metric: "Comments", value: 19 },
        { metric: "Impressions", value: 8250 },
        { metric: "Reach", value: 7450 },
        { metric: "Eng. Rate", value: 6.29, isPercentage: true },
      ],
      provider: SocialNetworks.LINKED_IN,
    },
    {
      date: "June 12, 2023 02:15pm",
      image: "/images/post-bba.jpeg",
      description:
        "Happy to share that we've achieved another major milestone. Great work team! #Milestone #Achievement",
      metrics: [
        { metric: "Likes", value: 380 },
        { metric: "Comments", value: 20 },
        { metric: "Impressions", value: 8300 },
        { metric: "Reach", value: 7500 },
        { metric: "Eng. Rate", value: 6.29, isPercentage: true },
      ],
      provider: SocialNetworks.LINKED_IN,
    },
    {
      date: "March 9, 2024 07:00pm",
      image: "/images/post-bba.jpeg",
      description:
        "Just wrapped up a successful strategy meeting. Excited about the plans we've put in place. #Strategy #Success",
      metrics: [
        { metric: "Likes", value: 395 },
        { metric: "Comments", value: 17 },
        { metric: "Impressions", value: 8350 },
        { metric: "Reach", value: 7550 },
        { metric: "Eng. Rate", value: 6.29, isPercentage: true },
      ],
      provider: SocialNetworks.LINKED_IN,
    },
    {
      date: "November 22, 2022 04:40pm",
      image: "/images/post-bba.jpeg",
      description:
        "Fantastic insights shared during today's innovation panel. #Innovation #PanelDiscussion",
      metrics: [
        { metric: "Likes", value: 310 },
        { metric: "Comments", value: 14 },
        { metric: "Impressions", value: 7700 },
        { metric: "Reach", value: 7000 },
        { metric: "Eng. Rate", value: 6.29, isPercentage: true },
      ],
      provider: SocialNetworks.LINKED_IN,
    },
    {
      date: "May 7, 2023 05:25pm",
      image: "/images/post-bba.jpeg",
      description:
        "Our team is expanding! Thrilled to announce our new office opening. #NewOffice #Expansion",
      metrics: [
        { metric: "Likes", value: 450 },
        { metric: "Comments", value: 22 },
        { metric: "Impressions", value: 8700 },
        { metric: "Reach", value: 7900 },
        { metric: "Eng. Rate", value: 6.29, isPercentage: true },
      ],
      provider: SocialNetworks.LINKED_IN,
    },
    {
      date: "July 15, 2023 01:30pm",
      image: "/images/post-bba.jpeg",
      description:
        "Great feedback from our latest client project. Proud of the team's hard work! #ClientFeedback #Proud",
      metrics: [
        { metric: "Likes", value: 360 },
        { metric: "Comments", value: 16 },
        { metric: "Impressions", value: 8200 },
        { metric: "Reach", value: 7400 },
        { metric: "Eng. Rate", value: 6.29, isPercentage: true },
      ],
      provider: SocialNetworks.LINKED_IN,
    },
    {
      date: "March 7, 2024 07:49pm",
      image: "/images/post-bba.jpeg",
      description:
        "Reflecting on the amazing journey so far and looking forward to more success. #Journey #Success",
      metrics: [
        { metric: "Likes", value: 330 },
        { metric: "Comments", value: 18 },
        { metric: "Impressions", value: 8000 },
        { metric: "Reach", value: 7200 },
        { metric: "Eng. Rate", value: 6.29, isPercentage: true },
      ],
      provider: SocialNetworks.LINKED_IN,
    },
  ]

  return [...instagramData, ...tikTokData, ...linkedInData]
}
