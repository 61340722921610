import { useAppSelector } from "../../../app/hooks"
import { selectUser } from "../../user/userSlice"
import styles from "./index.module.scss"

const WelcomeMessage = () => {
  const userName = useAppSelector(selectUser)?.name

  return (
    <p className={styles.welcomeMessage}>
      Welcome to Better Brand Analytics, {userName}
    </p>
  )
}

export default WelcomeMessage
