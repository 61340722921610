import { useMemo, useState } from "react"
import RadioButtons from "../../../app/shared/RadioButtons"
import styles from "./index.module.scss"
import FunnelTable from "./FunnelTable"
import DownloadImageButton, {
  FUNNEL_ID,
} from "../../../app/shared/DownloadImageButton"
import { useAppSelector } from "../../../app/hooks"
import { selectFunnelSteps } from "../homeSlice"

const Funnel = () => {
  const funnelSteps = useAppSelector(selectFunnelSteps)
  const [selectedOption, setSelectedOption] = useState(
    funnelSteps ? funnelSteps[0] : "base",
  )

  const options = useMemo(
    () =>
      funnelSteps?.map((step) => ({
        label: step,
        value: step,
      })),
    [funnelSteps],
  )

  return (
    <div className={styles.funnel}>
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          <p className={styles.title}>Funnel</p>
          <p className={styles.subtitle}>
            Your KPIs summarized by funnel stage, please refer to your model for
            details.
          </p>
        </div>
        <div className={styles.buttonsContainer}>
          {options && (
            <RadioButtons
              name="funnel"
              onSelectOption={setSelectedOption}
              options={options}
              selectedOption={selectedOption}
            />
          )}

          <DownloadImageButton id={FUNNEL_ID} fileName={"funnel"} />
        </div>
      </div>
      <FunnelTable selectedOption={selectedOption} />
    </div>
  )
}

export default Funnel
