import React from "react"
import styles from "./index.module.scss"
import { useEffect, useRef } from "react"
import { AppImages } from "../../appImages"

type Props = {
  handleClose?: (event: Event | React.MouseEvent) => void
  show: boolean
  children: JSX.Element
  className?: string
}

function Modal({ handleClose, show, children, className }: Props) {
  const ref = useRef<HTMLElement>(null)

  useEffect(() => {
    const listener = (event: Event) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return
      }
      if (handleClose) handleClose(event)
    }

    if (show) {
      document.addEventListener("mousedown", listener)
      document.addEventListener("touchstart", listener)
    }
    return () => {
      document.removeEventListener("mousedown", listener)
      document.removeEventListener("touchstart", listener)
    }
  }, [ref, handleClose, show])

  if (!show) return null

  return (
    <div className={`${styles.modal} ${className ?? ""}`}>
      <section className={styles.modalMain} ref={ref}>
        {handleClose && (
          <img
            src={AppImages.closeIcon}
            className={styles.closeButton}
            alt="X"
            onClick={handleClose}
          />
        )}
        {children}
      </section>
    </div>
  )
}

export default Modal
