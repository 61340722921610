class Storage {
  setItem(key: string, value: string) {
    try {
      if (typeof window === "undefined") return

      if (!value) return null
      const jsonValue = JSON.stringify(value)
      localStorage.setItem(key, jsonValue)
      return this
    } catch (error) {
      return null
    }
  }

  getItem(key: string) {
    try {
      if (typeof window === "undefined") return

      const item = localStorage.getItem(key)
      return item ? JSON.parse(item) : null
    } catch (error) {
      return null
    }
  }

  setAccessToken(accessToken: string | undefined) {
    try {
      if (typeof window === "undefined") return

      if (!accessToken) {
        this.removeItem("accessToken")
        return
      }
      const jsonValue = JSON.stringify(accessToken)
      localStorage.setItem("accessToken", jsonValue)
      return this
    } catch (error) {
      return null
    }
  }

  getAccessToken() {
    try {
      if (typeof window === "undefined") return

      return localStorage.getItem("accessToken")
    } catch (error) {
      return null
    }
  }

  getUserRole() {
    try {
      if (typeof window === "undefined") return

      return localStorage.getItem("userRole")
    } catch (error) {
      return null
    }
  }

  removeItem(key: string) {
    try {
      if (typeof window === "undefined") return

      localStorage.removeItem(key)
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  clear() {
    try {
      if (typeof window === "undefined") return

      localStorage.clear()
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }
}

const storage = new Storage()

export default storage
