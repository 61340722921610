import { FC } from "react"
import styles from "./index.module.scss"

interface Props {
  className?: string
}
const Loading: FC<Props> = ({ className }) => (
  <div className={`${styles.loading} ${className}`}>
    <div className={styles.dots}></div>
  </div>
)

export default Loading
