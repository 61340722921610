import { ChangeEvent, FC, useEffect, useState } from "react"
import styles from "./index.module.scss"
import { AppImages } from "../../../../app/appImages"

interface Props {
  onChange?: (value: string) => void
  searchValue?: string
}

export const SearchBar: FC<Props> = ({ onChange, searchValue }) => {
  const [search, setSearch] = useState(searchValue)

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      if (onChange && search !== undefined) onChange(search)
    }, 500)
    return () => clearTimeout(delayInputTimeoutId)
  }, [search])

  const handleSearchValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setSearch(value)
  }

  return (
    <div className={styles.search_container}>
      <div>
        <img src={AppImages.searchIcon} alt="search icon" />
      </div>

      <input
        type="search"
        value={search}
        onChange={handleSearchValueChange}
        placeholder="Search"
      />
    </div>
  )
}
