import React from "react"
import {
  Navigate,
  Outlet,
  createBrowserRouter,
  useLocation,
  useNavigate,
} from "react-router-dom"
import ErrorPage from "./routes/error-page"
import {
  CONTENT,
  FACEBOOK,
  HISTORICAL_CHARTS,
  HOME,
  INSTAGRAM,
  LOGIN,
  MOBILE_DEVICE,
  SOCIAL,
  TIKTOK,
} from "./routes"

import Login from "./routes/login"
import Home from "./routes/home"
import DashboardLayout from "./routes/dashboard"
import storage from "./app/storage"
import { useAppDispatch, useAppSelector } from "./app/hooks"
import {
  getUserData,
  selectUser,
  selectUserStatus,
} from "./features/user/userSlice"
import { SliceStatus } from "./app/sliceStatus"
import { useEffect } from "react"
import MobileDeviceMessage from "./features/mobile"
import { Social } from "./features/social/OverviewScreen"
import ContentScreen from "./features/social/ContentScreen"
import HistoricalChartsScreen from "./features/social/HistoricalChartsScreen"
import { Facebook } from "./features/facebook/OverviewScreen"
import FacebookContentScreen from "./features/facebook/ContentScreen"
import FacebookHistoricalChartsScreen from "./features/facebook/HistoricalChartsScreen"
import { InstagramOverview } from "./features/instagram/OverviewScreen"
import InstagramContentScreen from "./features/instagram/ContentScreen"
import InstagramHistoricalChartsScreen from "./features/instagram/HistoricalChartsScreen"
import { TiktokOverview } from "./features/tiktok/OverviewScreen"
import TikTokContentScreen from "./features/tiktok/ContentScreen"
import TikTokHistoricalChartsScreen from "./features/tiktok/HistoricalChartsScreen"

const router = createBrowserRouter([
  {
    errorElement: <ErrorPage />,
    element: (
      <RequireDesktopDevice>
        <RequireAuth>
          <CheckUserLayout>
            <DashboardLayout />
          </CheckUserLayout>
        </RequireAuth>
      </RequireDesktopDevice>
    ),
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: SOCIAL,
        element: (
          <>
            <Outlet />
          </>
        ),
        children: [
          {
            index: true,
            element: <Social />,
          },
          {
            path: CONTENT.slice(1),
            element: <ContentScreen />,
          },
          {
            path: HISTORICAL_CHARTS.slice(1),
            element: <HistoricalChartsScreen />,
          },
        ],
      },
      {
        path: FACEBOOK,
        element: (
          <>
            <Outlet />
          </>
        ),
        children: [
          {
            index: true,
            element: <Facebook />,
          },
          {
            path: CONTENT.slice(1),
            element: <FacebookContentScreen />,
          },
          {
            path: HISTORICAL_CHARTS.slice(1),
            element: <FacebookHistoricalChartsScreen />,
          },
        ],
      },
      {
        path: INSTAGRAM,
        element: (
          <>
            <Outlet />
          </>
        ),
        children: [
          {
            index: true,
            element: <InstagramOverview />,
          },
          {
            path: CONTENT.slice(1),
            element: <InstagramContentScreen />,
          },
          {
            path: HISTORICAL_CHARTS.slice(1),
            element: <InstagramHistoricalChartsScreen />,
          },
        ],
      },
      {
        path: TIKTOK,
        element: (
          <>
            <Outlet />
          </>
        ),
        children: [
          {
            index: true,
            element: <TiktokOverview />,
          },
          {
            path: CONTENT.slice(1),
            element: <TikTokContentScreen />,
          },
          {
            path: HISTORICAL_CHARTS.slice(1),
            element: <TikTokHistoricalChartsScreen />,
          },
        ],
      },
    ],
  },

  {
    path: LOGIN,
    element: (
      <RequireDesktopDevice>
        <Login />
      </RequireDesktopDevice>
    ),
  },
  {
    path: MOBILE_DEVICE,
    element: <MobileDeviceMessage />,
  },
])

export function RequireAuth({ children }: { children: JSX.Element }) {
  const accessToken = storage.getAccessToken()
  const user = useAppSelector(selectUser)
  const userIdle = useAppSelector(selectUserStatus) === SliceStatus.idle
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (accessToken && !user && userIdle) {
      dispatch(getUserData())
    }
  }, [accessToken, dispatch, user, userIdle])

  let location = useLocation()

  if (!accessToken) {
    return <Navigate to={LOGIN} state={{ from: location }} replace />
  }

  return children
}
export function CheckUserLayout({ children }: { children: JSX.Element }) {
  const user = useAppSelector(selectUser)

  let location = useLocation()

  if (!true) {
    return <Navigate to={HOME} replace />
  }

  return children
}

export function RequireDesktopDevice({ children }: { children: JSX.Element }) {
  const isMobile = window.innerWidth < 1050

  const navigate = useNavigate()

  let location = useLocation()

  useEffect(() => {
    const checkIsMobile = () => {
      const isMobile = window.innerWidth < 1050
      if (isMobile)
        navigate(MOBILE_DEVICE, { state: { from: location }, replace: true })
    }

    window.addEventListener("resize", checkIsMobile)

    return () => window.removeEventListener("resize", checkIsMobile)
  }, [location, navigate])

  if (isMobile) {
    return <Navigate to={MOBILE_DEVICE} state={{ from: location }} replace />
  }

  return children
}

export default router
