import { FC, useMemo } from "react"
import { HISTORICAL_ID } from "../../../../app/shared/DownloadImageButton"
import styles from "./index.module.scss"

import MultipleLineChart, { options } from "./MultipleLineChart"
import { ChartData, ChartDataset } from "chart.js"
import { HistoricalDataset } from "../historicalTypes"
import { useAppSelector } from "../../../../app/hooks"
import { selectMonth, selectYear } from "../historicalSlice"

const METRICS_COLORS = ["#92cdf4", "#f9c5c6", "#f16c60"]

interface Props {
  chartDatasets: HistoricalDataset[]
  metricName: string
}

const HistoricalGraph: FC<Props> = ({ chartDatasets, metricName }) => {
  const selectedMonth = Number(useAppSelector(selectMonth).value)
  const selectedYear = useAppSelector(selectYear)

  const startDate = useMemo(() => {
    const date = new Date()
    date.setMonth(selectedMonth - 1)
    date.setFullYear(Number(selectedYear.value))
    return date
  }, [selectedMonth, selectedYear.value])

  const data: ChartData<
    "line",
    (number | [number, number] | null)[],
    unknown
  >[] = useMemo(() => {
    startDate.setDate(1)
    startDate.setHours(0)
    startDate.setMinutes(0)
    startDate.setSeconds(0)
    const oneYearAgo = new Date(startDate)
    oneYearAgo.setFullYear(startDate.getFullYear() - 1)
    oneYearAgo.setMonth(startDate.getMonth())

    const labels = []

    while (oneYearAgo <= startDate) {
      labels.push(
        `${new Intl.DateTimeFormat("en-US", { month: "short" }).format(
          oneYearAgo,
        )}`,
      )

      oneYearAgo.setMonth(oneYearAgo.getMonth() + 1)
    }

    const datasets: ChartDataset<
      "line",
      (number | [number, number] | null)[]
    >[] = chartDatasets.map((chartData, index) => ({
      label: chartData.provider,
      data: chartData.data,
      borderColor: METRICS_COLORS[index],
      backgroundColor: METRICS_COLORS[index],
      cubicInterpolationMode: "default",
    }))

    const result = [
      {
        labels,
        datasets: datasets,
      },
    ]

    return result
  }, [chartDatasets, startDate])

  return (
    <div className={styles.historicalGraphContainer} id={metricName}>
      {data.map((dataset, i) => (
        <div className={styles.historicalGraph} key={i}>
          <MultipleLineChart data={dataset} options={options} />
        </div>
      ))}
    </div>
  )
}

export default HistoricalGraph
