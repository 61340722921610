import { useEffect, useRef, useState } from "react"
import styles from "./index.module.scss"
import { AppImages } from "../../appImages"

export interface SelectOption {
  value: string | number
  label: string
}

const Select = ({
  selectedOption,
  onClickOption,
  options,
  className,
  selectClassName,
  getIconFunction,
}: {
  selectedOption: SelectOption | undefined
  onClickOption: (option: SelectOption) => void
  options: SelectOption[]
  className?: string
  selectClassName?: string
  getIconFunction?: (value: string) => string
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)

  const toggleOpen = () => setOpen(!open)

  const onSelectOption = (option: SelectOption) => {
    setOpen(false)
    onClickOption(option)
  }

  useEffect(() => {
    if (open) {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          open &&
          ref.current &&
          !ref.current.contains(event.target as Node)
        ) {
          setOpen(false)
        }
      }
      document.addEventListener("click", handleClickOutside, true)
      return () => {
        document.removeEventListener("click", handleClickOutside, true)
      }
    }
  }, [open])

  return (
    <div className={styles.select + " " + className} ref={ref}>
      <div
        onClick={toggleOpen}
        className={styles.value + " " + selectClassName}
      >
        <p>
          {getIconFunction && (
            <img
              src={getIconFunction(selectedOption?.label || "")}
              alt={selectedOption?.label + " icon"}
            />
          )}

          {selectedOption?.label}
        </p>
        <img src={AppImages.selectArrowIcon} alt=">" />
      </div>
      <ul className={`${styles.dropDownList} ${open ? styles.open : ""}`}>
        {options.map((option) => (
          <li
            key={option.value}
            onClick={() => onSelectOption(option)}
            className={
              option.value === selectedOption?.value
                ? styles.selectedOption
                : ""
            }
          >
            {getIconFunction && (
              <img
                src={getIconFunction(option.label)}
                alt={option.label + " icon"}
              />
            )}{" "}
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Select
