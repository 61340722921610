import { useState } from "react"
import Button from "../Button"
import Modal from "../Modal"
import { AppImages } from "../../appImages"
import { useNavigate } from "react-router-dom"
import storage from "../../storage"
import axios from "axios"
import { LOGIN } from "../../../routes"
import styles from "./index.module.scss"
import { useAppDispatch } from "../../hooks"
import { resetLogin } from "../../../features/login/loginSlice"
import { resetUser } from "../../../features/user/userSlice"
import { resetHome } from "../../../features/home/homeSlice"

const LogoutButton = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [openConfirmation, setOpenConfirmation] = useState(false)

  const onLogout = () => {
    storage.setAccessToken(undefined)
    delete axios.defaults.headers.common["Authorization"]
    dispatch(resetLogin())
    dispatch(resetUser())
    dispatch(resetHome())
    setOpenConfirmation(false)
    navigate(LOGIN)
  }

  return (
    <>
      <img
        src={AppImages.logoutIcon}
        alt="logout"
        onClick={() => setOpenConfirmation(true)}
      />
      <Modal
        show={openConfirmation}
        handleClose={() => setOpenConfirmation(false)}
      >
        <>
          <p className={styles.text}>Are you sure you want to logout?</p>
          <div className={styles.buttons}>
            <Button
              text="Cancel"
              onClick={() => setOpenConfirmation(false)}
              className={styles.cancelButton}
            />
            <Button text="Confirm" onClick={onLogout} />
          </div>
        </>
      </Modal>
    </>
  )
}

export default LogoutButton
