import { useEffect } from "react"
import { AppImages } from "../../app/appImages"
import styles from "./index.module.scss"
import { useLocation, useNavigate } from "react-router-dom"

function MobileDeviceMessage() {
  const location = useLocation()
  const navigate = useNavigate()
  const from = location.state && location.state.from

  useEffect(() => {
    const checkIsMobile = () => {
      const isMobile = window.innerWidth < 1050
      if (!isMobile) navigate(from, { replace: true })
    }

    window.addEventListener("resize", checkIsMobile)

    return () => window.removeEventListener("resize", checkIsMobile)
  }, [from, navigate])

  return (
    <div className={styles.mobileDeviceMessage}>
      <div className={styles.logo}>
        <img src={AppImages.logoWhite} alt={"Better Brand"} />
      </div>
      <p>
        Better Brand Analytics is optimized for desktop browsers. To enjoy the
        best experience, please use a desktop-sized browser or adjust your
        current window size.
      </p>
    </div>
  )
}

export default MobileDeviceMessage
