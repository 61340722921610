import DownloadImageButton, {
  SEMESTER_KPI_ID,
} from "../../../../app/shared/DownloadImageButton"
import SixMonthCharts from "./Charts"
import { SixMonthKPITable } from "./Table"
import styles from "./index.module.scss"

export const SixMonthKPIView = () => {
  return (
    <div className={styles.monthlyKPIView} id={SEMESTER_KPI_ID}>
      <div className={styles.header}>
        <div>
          <h2 className={styles.title}>KPIs Over past 6 months</h2>
          <p className={styles.subtitle}>
            Subtitle here in case more context is needed
          </p>
        </div>
        <DownloadImageButton id={SEMESTER_KPI_ID} fileName={"semester-kpi"} />
      </div>
      <SixMonthKPITable />
      <SixMonthCharts />
    </div>
  )
}
