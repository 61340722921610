import { FC, useMemo } from "react"
import { getNumberWithCommas } from "../../../../app/getNumberWithCommas"
import { useAppSelector } from "../../../../app/hooks"
import Loading from "../../../../app/shared/Loading"
import MetricBadge from "../../../../app/shared/MetricBadge"
import { selectHomeMetrics } from "../../homeSlice"
import styles from "./index.module.scss"
import { MetricType } from "../../homeTypes"

interface MetricProp {
  title?: string
  description?: string
  difference?: number
  metric?: string
  loading: boolean
}

const Metrics = ({ isLoading }: { isLoading: boolean }) => {
  const homeMetrics = useAppSelector(selectHomeMetrics)

  const metrics: MetricProp[] = useMemo(() => {
    return (
      homeMetrics?.cardMetrics.map(
        ({ title, type, value, difference, description }) => ({
          title,
          description,
          metric: value
            ? `${type === MetricType.CURRENCY ? "$" : ""}${getNumberWithCommas(
                Number(value).toFixed(0),
              )}`
            : undefined,
          difference,
          loading: isLoading,
        }),
      ) || [{ loading: true }, { loading: true }, { loading: true }]
    )
  }, [homeMetrics, isLoading])

  return (
    <div className={styles.metrics}>
      {metrics.map(
        ({ title, description, loading, difference, metric }, index) => (
          <Metric
            key={`${title}${index}`}
            title={title}
            description={description}
            metric={metric}
            difference={difference}
            loading={loading}
          />
        ),
      )}
    </div>
  )
}

const Metric: FC<MetricProp> = ({
  title,
  description,
  difference,
  metric,
  loading,
}) => (
  <div className={`${styles.metricCard} ${loading ? styles.loading : ""}`}>
    {loading ? (
      <Loading />
    ) : (
      <>
        <p className={styles.title}>{title}</p>
        <p className={styles.description}>{description}</p>
        <div className={styles.stats}>
          <p className={styles.value}>{metric ? metric : "--"}</p>

          {difference !== null && difference !== undefined && (
            <MetricBadge numericValue={difference} isPercentage />
          )}
        </div>
      </>
    )}
  </div>
)

export default Metrics
