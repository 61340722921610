import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { SliceStatus } from "../../app/sliceStatus"
import { RootState } from "../../app/store"
import userAPI from "../user/userAPI"
import { UserType } from "./userTypeEnum"

export interface User {
  email: string
  name: string
  updated_at: string
  type: UserType
  facebookPages: { id: string; name: string }[]
  instagramAccount: { id: string; name: string }[]
  tikTokAccounts: { id: string; name: string }[]
}

export interface UserState {
  user?: User
  status: SliceStatus
}

const initialState: UserState = {
  user: undefined,
  status: SliceStatus.idle,
}

export const getUserData = createAsyncThunk("user/getUserData", async () => {
  const response = await userAPI.getUserData()

  return response
})

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<User>) => {
      state.user = action.payload
    },
    resetUser: (state) => {
      state.user = initialState.user
      state.status = initialState.status
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserData.pending, (state) => {
        state.status = SliceStatus.loading
      })
      .addCase(getUserData.fulfilled, (state, action) => {
        state.status = SliceStatus.loaded
        state.user = action.payload
      })
      .addCase(getUserData.rejected, (state) => {
        state.status = SliceStatus.failed
      })
  },
})

export const { setUserData, resetUser } = userSlice.actions

export const selectUser = (state: RootState) => state.user.user
export const selectUserStatus = (state: RootState) => state.user.status

export default userSlice.reducer
