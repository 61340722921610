import { useMemo } from "react"
import { useAppSelector } from "../../../../../app/hooks"
import MetricBadge from "../../../../../app/shared/MetricBadge"
import { selectMonth } from "../../../HistoricalChartsScreen/historicalSlice"
import { selectMonthlyKPIs, selectSixMonthKPIs } from "../../overviewSlice"
import { GoalScoreType } from "../../overviewTypes"
import styles from "./index.module.scss"
import { getSocialImage } from "../../../utils/getSocialImage"
import { SocialNetworks } from "../../../types/social-networks.enum"

interface RowType {
  isPercentage: boolean
  platform: string
  previousMonthAverage: string
  platformSubtitle?: string
  data: string[]
}

export const SixMonthKPITable = () => {
  const selectedMonth = useAppSelector(selectMonth)
  const sixMonthKPI = useAppSelector(selectSixMonthKPIs)

  const rowValues: RowType[] = useMemo(
    () =>
      sixMonthKPI.map((sixMonthKPI) => ({
        isPercentage: sixMonthKPI.isPercentage,
        platform: sixMonthKPI.platform,
        platformSubtitle: sixMonthKPI.platformSubtitle,
        previousMonthAverage: sixMonthKPI.previousMonthAverage,
        data: sixMonthKPI.data,
      })),
    [sixMonthKPI],
  )

  return (
    <div className={styles.monthlyKPITable}>
      <TableHeader currentMonth={selectedMonth.label} />
      {rowValues.map((rowValue, i) => (
        <Row rowValue={rowValue} key={i} />
      ))}
    </div>
  )
}

const TableHeader = ({ currentMonth }: { currentMonth: string }) => {
  const columnHeaders = useMemo(() => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ]

    // Encuentra el índice del mes proporcionado
    const monthIndex = months.findIndex(
      (m) => m.toLowerCase() === currentMonth.substring(0, 3).toLowerCase(),
    )

    if (monthIndex === -1) {
      throw new Error("Invalid month provided")
    }

    // Construye el array con los 6 meses anteriores y el mes proporcionado
    const result = []
    for (let i = 6; i >= 0; i--) {
      result.push(months[(monthIndex - i + 12) % 12])
    }

    return ["Platform", ...result, "Previous Month Average"]
  }, [currentMonth])

  return (
    <div className={styles.header}>
      {columnHeaders.map((title) => (
        <div key={title}>{title.replace("currentMonth", currentMonth)}</div>
      ))}
    </div>
  )
}

const Row = ({ rowValue }: { rowValue: RowType }) => (
  <div className={styles.row}>
    <div className={styles.platform}>
      <p className={styles.platformTitle}>{rowValue.platform}</p>
      <p>{rowValue.platformSubtitle}</p>
    </div>
    {rowValue.data.map((month) => (
      <div>
        {month} {rowValue.isPercentage ? " %" : ""}
      </div>
    ))}
    <div>{rowValue.previousMonthAverage}</div>
  </div>
)
