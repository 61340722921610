import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"

import tikTokAPI from "../tikTokAPI"
import { PostDetailItemType, PostSummaryType } from "./contentTypes"
import { SelectOption } from "../../../app/shared/Select"
import { SliceStatus } from "../../../app/sliceStatus"
import { RootState } from "../../../app/store"
import { GetContentResponse, PostDetailsResponse } from "../types/api-response"
import { SocialNetworks } from "../../social/types/social-networks.enum"
import { SortModel } from "../../social/types/sort-model"

interface TikTokContentState {
  month: SelectOption
  year: SelectOption
  account: string
  summary: PostSummaryType[]
  details: PostDetailItemType[]
  totalPages: number
  page: number
  status: SliceStatus
  detailStatus: SliceStatus
  search: string
  sort?: SortModel
}

const initialState: TikTokContentState = {
  month: { value: 1, label: "January" },
  year: { value: 2023, label: "2023" },
  account: "",
  summary: [],
  details: [],
  totalPages: 1,
  status: SliceStatus.idle,
  detailStatus: SliceStatus.idle,
  search: "",
  page: 1,
}

export const getContentMetrics = createAsyncThunk(
  "tikTokContent/getContentMetrics",
  async ({
    month,
    year,
    account,
  }: {
    month: SelectOption
    year: SelectOption
    account?: string
  }) => {
    const contentMetrics = await tikTokAPI.getContentMetrics({
      month:
        Number(month.value) < 10
          ? `0${month.value.toString()}`
          : month.value.toString(),
      year: year.value.toString(),
      provider: SocialNetworks.TIK_TOK,
      account,
    })

    return contentMetrics
  },
)

export const getContentDetailsMetrics = createAsyncThunk(
  "tikTokContent/getContentDetailsMetrics",
  async (
    {
      page,
      search,
      sortModel,
    }: { page: number; search: string; sortModel?: SortModel },
    { getState },
  ) => {
    const { month, year, account } = (getState() as RootState).tikTokContent

    const contentDetailsMetrics = await tikTokAPI.getContentDetailsMetrics({
      month:
        Number(month.value) < 10
          ? `0${month.value.toString()}`
          : month.value.toString(),
      year: year.value.toString(),
      page,
      provider: SocialNetworks.TIK_TOK,
      search,
      sortModel,
      account,
    })

    return contentDetailsMetrics
  },
)

export const tikTokContentSlice = createSlice({
  name: "tikTokContent",
  initialState,
  reducers: {
    setMonth: (state, action: PayloadAction<SelectOption>) => {
      state.month = action.payload
    },
    setYear: (state, action: PayloadAction<SelectOption>) => {
      state.year = action.payload
    },
    setSelectedAccount: (state, action: PayloadAction<string>) => {
      state.account = action.payload
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setSortModel: (state, action: PayloadAction<SortModel>) => {
      state.sort = action.payload
    },
    resetContentMetrics: (state) => {
      state.details = initialState.details
      state.summary = initialState.summary
      state.status = initialState.status
      state.account = initialState.account
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContentMetrics.pending, (state) => {
        state.status = SliceStatus.loading
        state.detailStatus = SliceStatus.loading
      })
      .addCase(
        getContentMetrics.fulfilled,
        (state, action: PayloadAction<GetContentResponse>) => {
          state.status = SliceStatus.loaded
          state.detailStatus = SliceStatus.loaded
          state.details = action.payload?.details.data
          state.summary = action.payload.summary
          state.totalPages = action.payload.details.pagination.totalPages
        },
      )
      .addCase(getContentMetrics.rejected, (state) => {
        state.status = SliceStatus.failed
        state.detailStatus = SliceStatus.failed
      })
      .addCase(getContentDetailsMetrics.pending, (state) => {
        state.detailStatus = SliceStatus.loading
      })
      .addCase(
        getContentDetailsMetrics.fulfilled,
        (state, action: PayloadAction<PostDetailsResponse>) => {
          state.detailStatus = SliceStatus.loaded
          state.details = action.payload.data
          state.totalPages = action.payload.pagination.totalPages
        },
      )
      .addCase(getContentDetailsMetrics.rejected, (state) => {
        state.detailStatus = SliceStatus.failed
      })
  },
})

export const {
  setMonth,
  setYear,
  resetContentMetrics,
  setSelectedAccount,
  setSearch,
  setPage,
  setSortModel,
} = tikTokContentSlice.actions

export const selectSocialStatus = (state: RootState) =>
  state.tikTokContent.status
export const selectSocialDetailStatus = (state: RootState) =>
  state.tikTokContent.detailStatus
export const selectMonth = (state: RootState) => state.tikTokContent.month
export const selectYear = (state: RootState) => state.tikTokContent.year
export const selectSearch = (state: RootState) => state.tikTokContent.search
export const selectAccount = (state: RootState) => state.tikTokContent.account
export const selectPostSummary = (state: RootState) =>
  state.tikTokContent.summary
export const selectPostDetailsItems = (state: RootState) =>
  state.tikTokContent.details

export const selectPostDetailsTotalPages = (state: RootState) =>
  state.tikTokContent.totalPages
export const selectPostCurrentPage = (state: RootState) =>
  state.tikTokContent.page
export const selectPostSortModel = (state: RootState) =>
  state.tikTokContent.sort

export default tikTokContentSlice.reducer
