const imagesFolder = "/images/"

export enum AppImages {
  logo = imagesFolder + "logo.svg",
  logoWhite = imagesFolder + "logoWhite.svg",
  smallLogo = imagesFolder + "smallLogo.svg",
  loginAsset = imagesFolder + "loginAsset.png",
  alertIcon = imagesFolder + "alertIcon.svg",
  homeIcon = imagesFolder + "homeIcon.svg",
  eventsIcon = imagesFolder + "eventsIcon.svg",
  socialIcon = imagesFolder + "socialIcon.svg",
  graySocialIcon = imagesFolder + "graySocialIcon.svg",
  logoutIcon = imagesFolder + "logoutIcon.svg",
  calendarIcon = imagesFolder + "calendarIcon.svg",
  menuArrowIcon = imagesFolder + "menuArrowIcon.svg",
  selectArrowIcon = imagesFolder + "selectArrowIcon.svg",
  downRedArrowIcon = imagesFolder + "downRedArrowIcon.svg",
  upGreenArrowIcon = imagesFolder + "upGreenArrowIcon.svg",
  closeIcon = imagesFolder + "closeIcon.svg",
  downloadIcon = imagesFolder + "downloadIcon.svg",
  tikTokIcon = imagesFolder + "tiktokIcon.svg",
  linkedInIcon = imagesFolder + "linkedinIcon.svg",
  instagramIcon = imagesFolder + "instagramIcon.svg",
  facebookIcon = imagesFolder + "facebook.svg",
  black_facebookIcon = imagesFolder + "facebook_black.png",
  appIcon = imagesFolder + "appIcon.svg",
  websiteIcon = imagesFolder + "websiteIcon.svg",
  leftArrowIcon = imagesFolder + "leftArrowIcon.svg",
  searchIcon = imagesFolder + "searchIcon.svg",
  sortIcon = imagesFolder + "sort.svg",
}
