export const LOGIN = "/login"
export const DASHBOARD = "/"
export const HOME = "/"
export const MOBILE_DEVICE = "/mobile-device"
export const SOCIAL = "/social"
export const FACEBOOK = "/facebook"
export const INSTAGRAM = "/instagram"
export const TIKTOK = "/tiktok"
export const CONTENT = "/content"
export const HISTORICAL_CHARTS = "/historical-charts"
