import { Outlet } from "react-router-dom"
import DashboardMenu from "./menu"
import styles from "./index.module.scss"

export default function DashboardLayout() {
  return (
    <div className={styles.dashboardContainer}>
      <DashboardMenu />
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  )
}
