import { FUNNEL_ID } from "../../../../app/shared/DownloadImageButton"
import BaseTable from "./BaseTable"
import styles from "./index.module.scss"
import { useAppSelector } from "../../../../app/hooks"
import {
  selectHomeMetrics,
  selectHomeStatus,
  selectMonth,
} from "../../homeSlice"
import { SliceStatus } from "../../../../app/sliceStatus"
import { HomeMetrics } from "../../homeTypes"
import { SelectOption } from "../../../../app/shared/Select"

const Labels = ({ currentMonth }: { currentMonth: SelectOption }) => {
  const date = new Date()
  date.setMonth((currentMonth.value as number) - 1)

  return (
    <div className={styles.labels}>
      <p>{new Intl.DateTimeFormat("en-US", { month: "long" }).format(date)}</p>
      <p>
        {new Intl.DateTimeFormat("en-US", { month: "long" }).format(
          date.setMonth(date.getMonth() - 1),
        )}
      </p>
      <p>
        {new Intl.DateTimeFormat("en-US", { month: "long" }).format(
          date.setMonth(date.getMonth() + 2),
        )}
      </p>
    </div>
  )
}

const getTable = (
  selectedOption: string,
  isLoading: boolean,
  homeMetrics?: HomeMetrics,
) => {
  return (
    <BaseTable
      isLoading={isLoading}
      homeMetrics={homeMetrics?.funnelMetrics[selectedOption]}
    />
  )
}

const FunnelTable = ({ selectedOption }: { selectedOption: string }) => {
  const isLoading = useAppSelector(selectHomeStatus) === SliceStatus.loading
  const homeMetrics = useAppSelector(selectHomeMetrics)
  const month = useAppSelector(selectMonth)

  return (
    <div className={styles.funnelTable} id={FUNNEL_ID}>
      <Labels currentMonth={month} />
      {getTable(selectedOption, isLoading, homeMetrics)}
    </div>
  )
}

export default FunnelTable
