import axios from "axios"

function getMetrics(month: string, year: string, controller?: AbortController) {
  return axios.get(`/home?date=${year}-${month}-01`, {
    signal: controller?.signal,
  })
}

function getHistoricalMetrics(month: string, year: string) {
  return axios.get(`/home/metrics?date=${year}-${month}-01`)
}

const homeAPI = { getMetrics, getHistoricalMetrics }
export default homeAPI
