import { FormEvent, useEffect } from "react"
import { AppImages } from "../../app/appImages"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import Button from "../../app/shared/Button"
import Input from "../../app/shared/Input"
import { SliceStatus } from "../../app/sliceStatus"
import styles from "./index.module.scss"
import {
  onChangeEmail,
  onChangePassword,
  selectEmail,
  selectLoginStatus,
  selectPassword,
  selectShowError,
  tryLogin,
} from "./loginSlice"
import formValidations from "../../app/formValidations"
import { Navigate, useNavigate } from "react-router-dom"
import { HOME } from "../../routes"
import storage from "../../app/storage"
import { toast } from "react-toastify"
import ContactModal from "./ContactModal"

export default function Login() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const email = useAppSelector(selectEmail)
  const password = useAppSelector(selectPassword)
  const showError = useAppSelector(selectShowError)
  const loginStatus = useAppSelector(selectLoginStatus)
  const accessToken = storage.getAccessToken()

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    dispatch(tryLogin())
  }

  useEffect(() => {
    if (loginStatus === SliceStatus.loaded) {
      navigate(HOME)
    }

    if (loginStatus === SliceStatus.failed)
      toast.error("The email or password is incorrect. Please try again.", {
        position: "bottom-left",
      })
  }, [loginStatus, navigate])

  if (accessToken) {
    return <Navigate to={HOME} replace />
  }

  return (
    <div className={styles.login}>
      <div className={styles.content}>
        <form className={styles.loginForm} onSubmit={onSubmit}>
          <img src={AppImages.logo} alt={""} className={styles.logo} />

          <h1>Log in</h1>
          <h2>Welcome! Please enter your details.</h2>
          <Input
            label={"Enter your email"}
            errorMessage={"Please enter your email address"}
            name={"email"}
            onChange={(event) => dispatch(onChangeEmail(event.target.value))}
            showError={showError && !formValidations.validateEmail(email)}
            value={email}
          />
          <Input
            type={"password"}
            label={"Enter your password"}
            errorMessage={"Please enter your password"}
            name={"password"}
            onChange={(event) => dispatch(onChangePassword(event.target.value))}
            showError={showError && password.length === 0}
            value={password}
          />

          <Button
            text={"Log in"}
            submit
            loading={loginStatus === SliceStatus.loading}
            className={styles.button}
          />

          <ContactModal />
        </form>
      </div>
      <div className={styles.assetContainer}>
        <img src={AppImages.loginAsset} alt={""} />
      </div>
    </div>
  )
}
