import { Link } from "react-router-dom"
import { useEffect } from "react"

import { AppImages } from "../../../app/appImages"
import Breadcrumb from "../../../app/shared/Breadcrumb"
import { PostSummary } from "./PostSummary"
import PostTable from "./PostTable"
import { SearchBar } from "./SearchBar"
import SortSelector from "./SortSelector"
import styles from "./index.module.scss"
import { CONTENT, INSTAGRAM } from "../../../routes"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import {
  getContentMetrics,
  selectMonth,
  selectYear,
  setMonth,
  setYear,
  setSelectedAccount,
  selectSearch,
  setSearch,
  setPage,
  setSortModel,
  selectAccount,
} from "./contentSlice"
import Header from "../Header"
import AccountSelector from "../../../app/shared/AccountSelector"
import { selectUser } from "../../user/userSlice"

const InstagramContentScreen = () => {
  const dispatch = useAppDispatch()
  const selectedMonth = useAppSelector(selectMonth)
  const selectedYear = useAppSelector(selectYear)
  const selectedAccount = useAppSelector(selectAccount)
  const searchValue = useAppSelector(selectSearch)
  const userInstagramAccounts = useAppSelector(selectUser)?.instagramAccount

  useEffect(() => {
    dispatch(
      getContentMetrics({
        month: selectedMonth,
        year: selectedYear,
        account: selectedAccount,
      }),
    )
  }, [selectedYear, selectedMonth, selectedAccount])

  return (
    <div className={styles.social}>
      <Header
        selectMonth={selectMonth}
        selectYear={selectYear}
        setMonth={setMonth}
        setYear={setYear}
      />

      <section>
        <Breadcrumb
          items={[
            <Link to={INSTAGRAM}>
              <img src={AppImages.graySocialIcon} alt="social icon" />
            </Link>,
            <Link to={`${INSTAGRAM}${CONTENT}`}>Content</Link>,
          ]}
        />
      </section>

      <section className={styles.channel}>
        <div className={styles.header}>
          <div className={styles.titleContainer}>
            <p className={styles.title}>Instagram</p>
            <p className={styles.subtitle}>Select your handle</p>
          </div>
          <div className={styles.buttonsContainer}>
            <AccountSelector
              options={[
                {
                  label: "All",
                  value: "",
                },
                ...(userInstagramAccounts?.map((account) => ({
                  label: account.name,
                  value: account.id,
                })) || []),
              ]}
              account={
                selectedAccount
                  ? {
                      value: selectedAccount,
                      label:
                        userInstagramAccounts?.find(
                          (x) => x.id === selectedAccount,
                        )?.name || "",
                    }
                  : {
                      label: "All",
                      value: "",
                    }
              }
              onAccountChange={(newSelectedAccount) =>
                dispatch(setSelectedAccount(newSelectedAccount))
              }
            />
          </div>
          <div></div>
        </div>
      </section>

      <>
        <section className={styles.summary}>
          <div className={styles.header}>
            <div className={styles.titleContainer}>
              <h2 className={styles.title}>Post Summary</h2>
            </div>
          </div>

          <PostSummary />
        </section>

        <section className={styles.details}>
          <div className={styles.header}>
            <h2 className={styles.title}>Post Details</h2>
            <div>
              <SearchBar
                searchValue={searchValue}
                onChange={(value) => {
                  dispatch(setSearch(value))
                  dispatch(setPage(1))
                }}
              />
              <SortSelector
                onChange={(field, direction) => {
                  dispatch(setSortModel({ field, direction }))
                  dispatch(setPage(1))
                }}
              />
            </div>
          </div>

          <PostTable />
        </section>
      </>
    </div>
  )
}

export default InstagramContentScreen
